import { IconButton, Stack, Typography } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import classes from "./OrderDetails.module.css";
// import ConfirmOrder from "./ConfirmOrder";

const OrderDetails = ({ item, addItemQty, removeItemQty, type }) => {
  // console.log("order details ", item);
  return (
    <>
      <Stack
        display="flex"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.addingbar}
      >
        <Stack
          display="flex"
          direction="row"
          justifyContent="start"
          alignItems="center"
          width="175px"
        >
          <Typography className={classes.name}>{item.name}</Typography>
        </Stack>
        <Stack
          display="flex"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="240px"
        >
          <Stack
            display="flex"
            direction="row"
            justifyContent="start"
            alignItems="center"
          >
            {type === "suborder" ? (
              <p style={{ marginLeft: "40px" }}>{item.quantity}</p>
            ) : (
              <>
                <IconButton onClick={() => removeItemQty(item)}>
                  <RemoveIcon className={classes.icon1} />
                </IconButton>
                <p className={classes.qty}>{item.quantity}</p>
                <IconButton onClick={() => addItemQty(item)}>
                  <AddIcon className={classes.icon2} />
                </IconButton>
              </>
            )}
          </Stack>

          <Stack
            display="flex"
            direction="row"
            justifyContent="start"
            alignItems="center"
          >
            <Typography>
              <b className={classes.RM}>Rm</b>{" "}
              <span className={classes.price}>
                {item.totalPrice.toFixed(2)}
              </span>
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default OrderDetails;
