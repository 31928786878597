import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import classes from "./BackButton.module.css";
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const BackButton = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Use navigate(-1) to navigate to the previous page
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ x: -20, opacity: 0.3 }}
        animate={{
          x: 0,
          opacity: 1,
          transition: { ease: "easeIn", duration: 0.8 },
        }}
        exit={{ x: -20, opacity: 0.3 }}
        className={classes.btnContainer}
      >
        <motion.button
          whileHover={{ scale: 1.1 }}
          transition={{ stiffness: 500, type: "spring" }}
          whileTap={{
            scale: 0.9, // Adjust the scale for the button during click
            opacity: 0.7, // Adjust opacity during click
            transition: { ease: "easeOut", duration: 0.2 }, // Ease out and duration
          }}
          className={classes.backBtn}
          onClick={handleGoBack}
        >
          <ArrowBackIosNewIcon className={classes.HomeIcon} />
        </motion.button>
      </motion.div>
    </AnimatePresence>
  );
};

export default BackButton;
