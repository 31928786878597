import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FlatwareIcon from "@mui/icons-material/Flatware";
// import InventoryIcon from "@mui/icons-material/Inventory";
import MenuButton from "../Reusable/MenuButton";

import { useGetSettingsQuery } from "../api/apiSlice";
// import BasicModal from "../Reusable/BasicModal";
// import { modalData1 } from "../components/Order/ModalData";
import { useDispatch, useSelector } from "react-redux";
import { selectTable, setTableError, setUser } from "../store/appSlice";
import { auth } from "../services/firebase";
import { signOut } from "firebase/auth";
import { Button } from "@mui/material";
import classes from "./Home.module.css";

function Home({ tableNo, type }) {
  const navigate = useNavigate();
  const { data, isLoading, isError, isFetching } = useGetSettingsQuery();
  const { app } = useSelector(selectTable);
  const dispatch = useDispatch();
  // console.log("table Home: ", app);
  // console.log("data: ", data, isLoading, isError, isFetching);
  const queryParams = { "table-no": tableNo, type: type };
  // console.log("table-no: ", queryParams);

  // Convert the object into a query string
  const queryString = new URLSearchParams(queryParams).toString();

  const goTo = (category) => {
    // console.log(category);

    navigate(`/menu?${queryString}`, { state: { category: category } });
  };

  const checkCanGoToCart = () => {
    // console.log("checkCanGoToCart: ", tableNo, typeof tableNo);
    if (
      tableNo === "null" ||
      tableNo === "undefined" ||
      tableNo === "" ||
      tableNo === null ||
      tableNo === undefined
    ) {
      dispatch(
        setTableError({
          isClose: true,
          isTableError: true,
          isTableMessage:
            "Please scan from restuarant to place orders and check cart items",
          canPlaceOrder: false,
        })
      );
    } else {
      navigate(`/checkout?${queryString}`);
    }
  };

  // const [showModal, setShowModal] = useState(false);

  const logoutHandler = () => {
    signOut(auth)
      .then(() => {
        dispatch(
          setUser({
            id: "",
            phone: "",
            currentOrderId: "",
          })
        );
        window.location.href = "/";
        alert("Successfully logged out!!!");
      })
      .catch((e) => {
        console.log("er", e);
      });
  };

  // const MobileOTPModal = () => {
  //   window.recaptchaVerifier = new RecaptchaVerifier(auth, "sign-in-button", {
  //     size: "invisible",
  //     callback: (response) => {
  //       // reCAPTCHA solved, allow signInWithPhoneNumber.
  //       // console.log("response: ", response);
  //       // onSignInSubmit();
  //     },
  //   });

  //   const [phoneNumber, setPhoneNumber] = useState("");
  //   const [otp, setOtp] = useState("");
  //   const [otpPrompt, setOtpPrompt] = useState(false);

  //   const handleSubmit = (e) => {
  //     e.preventDefault();

  //     const appVerifier = window.recaptchaVerifier;
  //     signInWithPhoneNumber(auth, `+60${phoneNumber}`, appVerifier)
  //       .then((confirmationResult) => {
  //         window.confirmationResult = confirmationResult;
  //         setOtpPrompt(true);
  //       })
  //       .catch((e) => {
  //         console.log(e);
  //         const signInButton = document.getElementById("sign-in-button");
  //         if (signInButton && signInButton.firstChild) {
  //           signInButton.removeChild(signInButton.firstChild);
  //         }
  //         window.recaptchaVerifier = new RecaptchaVerifier(
  //           auth,
  //           "sign-in-button",
  //           {
  //             size: "invisible",
  //             callback: (response) => {
  //               // reCAPTCHA solved, allow signInWithPhoneNumber.
  //               // onSignInSubmit();
  //             },
  //           }
  //         );
  //       });
  //   };

  //   const handleOtpSubmit = (e) => {
  //     e.preventDefault();
  //     window.confirmationResult
  //       .confirm(otp)
  //       .then((result) => {
  //         const phoneNumber = result.user.phoneNumber;
  //         console.log("user: ", phoneNumber);

  //         const docRef = doc(db, "users", phoneNumber);
  //         getDoc(docRef).then((docSnap) => {
  //           if (docSnap.exists()) {
  //             console.log("Login:", docSnap.data());
  //             const user = docSnap.data();
  //             dispatch(setShowModal(false));
  //             dispatch(
  //               setUser({
  //                 id: user.userId,
  //                 phone: user.phone,
  //                 currentOrderId: user.currentOrderId,
  //               })
  //             );
  //           } else {
  //             // docSnap.data() will be undefined in this case
  //             console.log("No such document!");
  //           }
  //         });
  //       })
  //       .catch((e) => {
  //         console.log("handleOtpSubmit: ", e);
  //       });
  //   };

  //   return (
  //     <>
  //       <div
  //         onClick={() => dispatch(setShowModal(false))}
  //         className={classes.backdrop}
  //       ></div>
  //       <div className={classes.modal}>
  //         <form className="d-flex flex-wrap">
  //           <label className={classes.modalLabel} htmlFor="mobile-number">
  //             Enter Mobile Number
  //           </label>
  //           <input
  //             className={classes.mobileInput}
  //             id="mobile-number"
  //             type="number"
  //             onChange={(e) => setPhoneNumber(e.target.value)}
  //             value={phoneNumber}
  //           />
  //           <div style={{ width: "100%", marginBottom: "1.5rem" }}></div>
  //           {otpPrompt ? (
  //             <>
  //               <label className={classes.modalLabel} htmlFor="mobile-number">
  //                 Enter OTP
  //               </label>
  //               <input
  //                 className={classes.mobileInput}
  //                 id="mobile-number"
  //                 type="number"
  //                 onChange={(e) => setOtp(e.target.value)}
  //                 value={otp}
  //               />
  //               <div style={{ width: "100%", marginBottom: "1.5rem" }}></div>
  //               <button
  //                 onClick={handleOtpSubmit}
  //                 style={{ width: "fit-content", margin: "auto" }}
  //                 className={classes.listBtn}
  //               >
  //                 Verify OTP
  //               </button>
  //             </>
  //           ) : (
  //             <button
  //               onClick={handleSubmit}
  //               style={{ width: "fit-content", margin: "auto" }}
  //               className={classes.listBtn}
  //             >
  //               Send OTP
  //             </button>
  //           )}
  //         </form>
  //       </div>
  //     </>
  //   );
  // };

  const table = app.table.data?.name.split("|");

  return (
    <div className={classes.bannerImg}>
      <div className={classes.innerSection}>
        {app.user.id && app.user.phone ? (
          <button onClick={logoutHandler} className={classes.loginBtn}>
            Logout
          </button>
        ) : (
          <button onClick={() => navigate("/")} className={classes.loginBtn}>
            Login
          </button>
        )}
        <div  className={classes.logodiv}>
          <img
            className={classes.logo}
            src="/images/albarakahlogo.png"
            alt="no-img"
          />
        </div>
        <div className={classes.shoplogodiv}>
          {" "}
          <img
            className={classes.shoplogo}
            src="/images/logo.png"
            alt="no-img"
          />
        </div>

        {data?.maintenance?.enabled ? (
          <p className={`${classes.text} ${classes.textAlign}`}>
            {data.maintenance?.message}
          </p>
        ) : (
          <div className="row " style={{ marginTop: "20px" }}>
            <div className="col-sm-12">
              {/* <motion.ol
                transition={{ staggerChildren: 0.05 }}
                className={classes.homeOl}
              > */}
              {/* <motion.li
                  variants={{
                    hidden: { opacity: 0, scale: 0.5 },
                    visible: { opacity: 1, scale: [0.8, 1.3, 1] },
                  }}
                  initial="hidden"
                  animate="visible"
                  transition={{ type: "spring" }}
                  className={classes.homeList}
                > */}
              <MenuButton categoryList={data?.categories || []} goTo={goTo} />
              {/* </motion.li> */}
              {/* </motion.ol> */}
            </div>
            <div className="col-sm-12">
              <ol
                transition={{ staggerChildren: 0.05 }}
                className={classes.homeOl}
              >
                <Link to={`/items?${queryString}`} className={classes.listText}>
                  <li
                    variants={{
                      hidden: { opacity: 0, scale: 0.5 },
                      visible: { opacity: 1, scale: [0.8, 1.3, 1] },
                    }}
                    initial="hidden"
                    animate="visible"
                    transition={{ type: "spring" }}
                    className={classes.homeList}
                  >
                    {" "}
                    <Button size="small" className={classes.listBtn}>
                      <ShoppingCartIcon />
                      {/* <Link
                        to={`/items?${queryString}`}
                        className={classes.listText}
                      > */}
                      <span className={classes.menuText}>Order</span>
                      {/* </Link> */}
                    </Button>
                  </li>
                </Link>
              </ol>
            </div>
            <div className="col-sm-12">
              <ol
                // transition={{ staggerChildren: 0.05 }}
                className={classes.homeOl}
              >
                <Link
                  to={`/checkout?${queryString}`}
                  className={classes.listText}
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default link behavior
                    checkCanGoToCart(); // Print something in the console
                  }}
                >
                  <li
                    // variants={{
                    //   hidden: { opacity: 0, scale: 0.5 },
                    //   visible: { opacity: 1, scale: [0.8, 1.3, 1] },
                    // }}
                    initial="hidden"
                    animate="visible"
                    transition={{ type: "spring" }}
                    className={classes.homeList}
                  >
                    <Button
                      whileHover={{ scale: 1.1 }}
                      transition={{ type: "spring", stiffness: 500 }}
                      className={classes.listBtn}
                    >
                      <FlatwareIcon />
                      <span className={classes.menuText}>Cart</span>
                    </Button>
                  </li>
                </Link>
              </ol>
            </div>
            {/* <div className="col-sm-12">
              <motion.ol
                transition={{ staggerChildren: 0.05 }}
                className={classes.homeOl}
              >
                <motion.li
                  variants={{
                    hidden: { opacity: 0, scale: 0.5 },
                    visible: { opacity: 1, scale: [0.8, 1.3, 1] },
                  }}
                  initial="hidden"
                  animate="visible"
                  transition={{ type: "spring" }}
                  className={classes.homeList}
                >
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    transition={{ type: "spring", stiffness: 500 }}
                    className={classes.listBtn}
                  >
                    <InventoryIcon />
                    <Link to="" className={classes.listText}>
                      Pick up
                    </Link>
                  </motion.button>
                </motion.li>
              </motion.ol>
            </div> */}
          </div>
        )}
        <div>
          {app.user.id && app.user.phone && (
            <p className={classes.statusText}>
              Logged in with phone: {app.user.phone}
            </p>
          )}
          {tableNo === "table-takeaway" ? (
            <p className={classes.statusText}>
              Table No: {app.table.data?.tableNo}
            </p>
          ) : app.table.canPlaceOrder ? (
            <>
              <p className={classes.tableNo}>
                Branch: {table[0].toUpperCase()}
              </p>
              <p className={classes.tableNo}>Table: {table[2].toUpperCase()}</p>
              <p className={classes.statusText}>
                Status: {app.table.data?.status}
              </p>
            </>
          ) : (
            <>
              <p className={classes.tableNo}>
                Table No: Please scan the QR Code from restuarant
              </p>
              <p className={classes.statusText}>
                Status: Public (Can't able to order)
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
