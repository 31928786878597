import { Backdrop, Button, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { selectIsLoading } from "../store/appSlice";

const Spinner = () => {
  const isLoading = useSelector(selectIsLoading);
  // console.log("isLoading: ", isLoading);
  return (
    <div>
      {/* <Button onClick={handleOpen}>Show backdrop</Button> */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Spinner;
