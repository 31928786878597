import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  item: {
    id: "",
    category: {
      id: "",
      name: "",
    },
    foodType: "",
    imgUrl:
      "https://firebasestorage.googleapis.com/v0/b/albarakah-fusion-4afcb.appspot.com/o/images%2Flogo%2Falbarakah%20logo%20with%20slogan.jpg?alt=media&token=a77e8d4b-fa30-488a-ac70-95097a6586bb",
    isAvailable: false,
    price: 0,
    minItemCount: 0,
    name: "",
    subCategory: {
      id: "",
      name: "",
    },
    totalItemCount: 0,
  },
};

export const itemSlice = createSlice({
  name: "item",
  initialState,
  reducers: {
    setItem: (state, action) => {
      state.item = action.payload;
    },
  },
});

export const { setItem } = itemSlice.actions;

export const selectItem = (state) => state.item;

export default itemSlice.reducer;
