import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { cookieOptions, isLocalStorageSupports } from "../utils";

const initialState = {
  cartItems: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addQtyToCart: (state, action) => {
      const cartItem = action.payload;
      const index = state.cartItems.findIndex(
        (item) => item.id === cartItem.id
      );

      if (index !== -1) {
        state.cartItems[index].quantity += 1;
        state.cartItems[index].totalPrice =
          state.cartItems[index].quantity * state.cartItems[index].itemPrice;

        if (isLocalStorageSupports()) {
          localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        } else {
          Cookies.set(
            "cartItems",
            JSON.stringify(state.cartItems),
            cookieOptions
          );
        }
      }
    },
    removeQtyFromCart: (state, action) => {
      const cartItem = action.payload;
      const index = state.cartItems.findIndex(
        (item) => item.id === cartItem.id
      );

      if (index !== -1) {
        const quantity = state.cartItems[index].quantity;
        if (quantity === 1) {
          // Use filter to remove the item from the array
          state.cartItems = state.cartItems.filter(
            (item) => item.id !== cartItem.id
          );
        } else {
          // Create a new array with the quantity decremented
          state.cartItems[index] = {
            ...state.cartItems[index],
            quantity: quantity - 1,
          };
          state.cartItems[index].totalPrice =
            state.cartItems[index].quantity * state.cartItems[index].itemPrice +
            1;
        }
        if (isLocalStorageSupports()) {
          localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        } else {
          Cookies.set(
            "cartItems",
            JSON.stringify(state.cartItems),
            cookieOptions
          );
        }
      }
    },
    addToCart: (state, action) => {
      const cartItem = {
        ...action.payload,
        quantity: 1,
        status: "",
        totalPrice: action.payload.itemPrice,
        index: state.cartItems.findIndex(addToCart) + 1,
      };
      state.cartItems.push(cartItem);
      // const cartItemIndex={...state.cartItems, index : state.cartItems.findIndex(addToCart)}
      // state.cartItems[cartItemIndex].push(cartItemIndex);
      // console.log("addTocart", state.cartItems.findIndex(addToCart));
      if (isLocalStorageSupports()) {
        localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      } else {
        Cookies.set(
          "cartItems",
          JSON.stringify(state.cartItems),
          cookieOptions
        );
      }
    },
    removeFromCart: (state, action) => {
      const cartItem = action.payload;
      const arr = [...state.cartItems];
      state.cartItems = [...arr.filter((item) => item.id !== cartItem.id)]; // filtering only this cart item and storing it in state
      if (isLocalStorageSupports()) {
        localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      } else {
        Cookies.set(
          "cartItems",
          JSON.stringify(state.cartItems),
          cookieOptions
        );
      }
    },
    clearCart: (state, action) => {
      state.cartItems = [];
      if (isLocalStorageSupports()) {
        localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      } else {
        Cookies.set(
          "cartItems",
          JSON.stringify(state.cartItems),
          cookieOptions
        );
      }
    },
    setCart: (state, action) => {
      state.cartItems = [...action.payload];
    },
    updateCartItem: (state, action) => {
      const cartItemsUpdated = action.payload;
      const arr = [];
      if (typeof cartItemsUpdated == "array") {
        cartItemsUpdated.map((item) => arr.push({ ...item, status: "booked" }));
        state.cartItems = state.cartItems.filter((item) => item.status !== "");
        state.cartItems[action.payload.index] = arr;
      } else {
        const arr1 = { ...action.payload, status: "booked" };
        state.cartItems = state.cartItems.filter((item) => item.status !== "");
        state.cartItems[action.payload.index] = arr1;
      }
      // const arr1={...action.payload,status:"booked"}
      // const temp=state.cartItems.filter((item) => item.index !== arr.index)

      // console.log("updateCartItem: ", cartItemsUpdated?.index);
      // state.cartItems = cartItemsUpdated;
      if (isLocalStorageSupports()) {
        localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      } else {
        Cookies.set(
          "cartItems",
          JSON.stringify(state.cartItems),
          cookieOptions
        );
      }
    },
  },
});

export const {
  addQtyToCart,
  removeQtyFromCart,
  addToCart,
  removeFromCart,
  clearCart,
  updateCartItem,
  setCart,
} = cartSlice.actions;

export const selectCartItems = (state) => state.cart;

export default cartSlice.reducer;
