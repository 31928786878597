import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getCountFromServer,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Card, Divider, Stack, Typography } from "@mui/material";

import classes from "./Checkout.module.css";
import ButtonAppBar from "../components/Order/ButtonAppBar";
import OrderDetails from "../components/Order/OrderDetails";
import DineButton from "../components/Order/DineButton";
import {
  addQtyToCart,
  clearCart,
  removeQtyFromCart,
  selectCartItems,
} from "../store/cartSlice";
import { db } from "../services/firebase";
import { selectTable, setUser } from "../store/appSlice";
import { successNotification } from "../utils/notification";
import {
  cookieOptions,
  getSingaporeDate,
  isLocalStorageSupports,
} from "../utils";
import { useGetOrderByIdQuery, useGetSettingsQuery } from "../api/apiSlice";
import TakeAwayButton from "../components/Order/TakeAwayButton";
import Cookies from "js-cookie";

const Checkout = ({ tableNo, type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cartItems } = useSelector(selectCartItems);
  const { app } = useSelector(selectTable);
  const currentOrderId = app?.user?.currentOrderId;
  console.log("app.user: ", app.user, currentOrderId);
  // const currentOrderId = isLocalStorageSupports()
  //   ? localStorage.getItem("current-order-id")
  //   : Cookies.get("current-order-id");
  // const currentOrderId = store.get("current-order-id");
  const { data: settings } = useGetSettingsQuery();
  const { data: currentOrder } = useGetOrderByIdQuery({
    docId: currentOrderId,
  });
  console.log(
    "currentOrder: ",
    currentOrder,
    tableNo,
    type,
    app.table.data,
    cartItems
  );

  const taxPercentage = 6;
  const serviceChargePercentage = 10;

  const calculateTotals = useMemo(() => {
    let totalAmount = 0;
    let totalQuantity = 0;
    cartItems.forEach((item) => {
      // console.log("---", item);
      totalAmount += item.totalPrice;
      totalQuantity += item.quantity;
    });
    currentOrder?.orderedItems.forEach((orderedItem) => {
      orderedItem.items.forEach((item) => {
        totalAmount += item.totalPrice;
        totalQuantity += item.quantity;
      });
    });
    // 7% tax
    let taxAmount = totalAmount * (taxPercentage / 100);
    let packagingCharge =
      tableNo === "table-takeaway" && app?.table?.data?.tableNo === "take-away"
        ? settings.price_details.packaging_charges
        : 0;

    let serviceCharge = totalAmount * (serviceChargePercentage / 100);

    let totalAmountWithTax = (
      totalAmount +
      taxAmount +
      packagingCharge +
      serviceCharge
    ).toFixed(2);

    let totalAmountWithTaxRoundOff = Math.ceil(totalAmountWithTax).toFixed(2); // round - prev, change this based on client

    // to have 2 decimal values
    totalAmount = totalAmount.toFixed(2);
    taxAmount = taxAmount.toFixed(2);
    serviceCharge = serviceCharge.toFixed(2);

    return {
      totalAmount,
      taxAmount,
      totalAmountWithTax,
      totalAmountWithTaxRoundOff,
      totalQuantity,
      serviceCharge,
    };
  }, [
    app?.table?.data?.tableNo,
    cartItems,
    currentOrder?.orderedItems,
    settings?.price_details?.packaging_charges,
    tableNo,
  ]);

  const {
    totalAmount,
    taxAmount,
    totalAmountWithTax,
    totalAmountWithTaxRoundOff,
    totalQuantity,
    serviceCharge,
  } = calculateTotals;

  const addItemQty = (item) => {
    dispatch(addQtyToCart(item));
  };

  const removeItemQty = (item) => {
    dispatch(removeQtyFromCart(item));
  };

  const generateOrderNo = async (type) => {
    const currentDate = getSingaporeDate();
    // Get the start time of today (midnight)
    const todayStartTime = new Date(currentDate);
    todayStartTime.setHours(0, 0, 0, 0);

    // Get the start time of tomorrow (midnight)
    const tomorrowStartTime = new Date(currentDate);
    tomorrowStartTime.setDate(currentDate.getDate() + 1);
    tomorrowStartTime.setHours(0, 0, 0, 0);

    const q = query(
      collection(db, "orders"),
      where("orderedTimestamp", ">=", todayStartTime.getTime()),
      where("orderedTimestamp", "<", tomorrowStartTime.getTime()),
      where("type", "==", type) // need to change it to general
    );

    const snapshot = await getCountFromServer(q);
    console.log("count: ", snapshot.data().count);

    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = String(currentDate.getFullYear());

    const orderNo = `${type === "dine-in" ? "D" : "T"}${year}${month}${day}${
      snapshot.data().count
    }`;

    return orderNo;
  };

  const handleConfirmOrder = async () => {
    const currentTimestamp = getSingaporeDate().getTime();

    let type = "dine-in";

    if (
      tableNo === "table-takeaway" &&
      app?.table?.data?.tableNo === "take-away"
    ) {
      type = "take-away";
    }

    if (app.user.phone === "" && app.user.id === "") {
      alert("Please login to place order!!!");
      navigate(`/?table-no=${tableNo}&type=${type}`);
      return;
    }

    const orderNo = await generateOrderNo(type);

    const tableDetail = JSON.parse(
      isLocalStorageSupports()
        ? localStorage.getItem("table-detail")
        : Cookies.get("table-detail")
    );
    // const tableDetail = JSON.parse(store.get("table-detail"));

    console.log("checout tableDetail: ", tableDetail, tableNo);
    let tableId = tableDetail?.tableNo || tableNo;
    const splittedTableDet = tableId.split("|");
    const order = {
      cancelReason: "",
      category: "",
      orderNo,
      orderedItems: [],
      orderedTimestamp: currentTimestamp,
      suborderTimestamp: currentTimestamp,
      paymentMethod: "",
      paymentStatus: "",
      status: "booked",
      tableNo: type === "take-away" ? type : tableId,
      branch: splittedTableDet[0],
      tablePlaced: splittedTableDet[1],
      taxPercentage,
      taxPrice: +taxAmount,
      serviceChargePercentage,
      serviceCharge: +serviceCharge,
      totalPrice: +totalAmount,
      packagingPrice: settings.price_details.packaging_charges,
      totalPriceWithTax: +totalAmountWithTaxRoundOff,
      totalQuantity: cartItems.length,
      type, // dine-in, take-away
      userDetails: {
        name: "",
        email: "",
        phone: app.user.phone,
      },
    };

    const orderedItems = cartItems.map((item) => ({
      category: item.category,
      subCategory: item.subCategory,
      id: item.id,
      name: item.name,
      perQuantityPrice: item.itemPrice,
      quantity: item.quantity,
      status: "booked",
      totalPrice: item.totalPrice,
    }));

    order.orderedItems.push({
      items: orderedItems,
      status: "booked",
      timestamp: currentTimestamp,
    });

    const docRef = await addDoc(collection(db, "orders"), order);
    console.log("Document written with ID: ", docRef.id);

    if (type === "dine-in") {
      // const washingtonRef = doc(db, "tables", tableId);
      // await updateDoc(washingtonRef, {
      //   currentOrderId: docRef.id,
      // });

      Promise.all([
        updateDoc(doc(db, "tables", tableId), {
          currentOrderId: docRef.id,
        }),
        updateDoc(doc(db, "users", app?.user?.phone), {
          currentOrderId: docRef.id,
        }),
      ])
        .then(() => {
          console.log("Updated table and users successfully");
          dispatch(
            setUser({
              id: app?.user?.id,
              phone: app?.user.phone,
              currentOrderId: docRef.id,
            })
          );
          dispatch(clearCart());
        })
        .catch((e) => {
          console.log("error in updating doc: ", e);
        });
    }

    // console.log("order: ", cartItems);
    successNotification("Order Placed Successfully!!!");
    localStorage.setItem("current-order-id", docRef.id);
    if (isLocalStorageSupports()) {
      localStorage.setItem("current-order-id", docRef.id);
    } else {
      Cookies.set("current-order-id", docRef.id, cookieOptions);
    }
    // store.set("current-order-id", docRef.id);
    // dispatch(clearCart());
  };

  const handleUpdateOrder = async () => {
    const currentTimestamp = getSingaporeDate().getTime();
    // console.log("handleUpdateOrder", currentOrderId, cartItems);

    const orderRef = doc(db, "orders", currentOrderId);

    const orderedItems = cartItems.map((item) => ({
      category: item.category,
      subCategory: item.subCategory,
      id: item.id,
      name: item.name,
      perQuantityPrice: item.itemPrice,
      quantity: item.quantity,
      status: "booked",
      totalPrice: item.totalPrice,
    }));

    await updateDoc(orderRef, {
      orderedItems: arrayUnion({
        items: orderedItems,
        status: "booked",
        timestamp: currentTimestamp,
      }),
      suborderTimestamp: currentTimestamp,
      taxPrice: +taxAmount,
      totalPrice: +totalAmount,
      totalPriceWithTax: +totalAmountWithTaxRoundOff,
      totalQuantity: +totalQuantity,
    });

    dispatch(clearCart());
  };

  // const dataToUpdate=[...cartItems,cartItems[0].status:"booked"];
  // console.log("order: ", cartItems);
  // console.log("order update: ", dataToUpdate);

  const queryParams = { "table-no": tableNo, type: type };
  const queryString = new URLSearchParams(queryParams).toString();

  const getStatusStyle = (status) => {
    switch (status) {
      case "booked":
        return {
          backgroundColor: "lightblue",
          color: "black",
          display: "inline-block",
          padding: "8px 16px",
          borderRadius: "4px",
          fontSize: "14px",
          fontWeight: "bold",
        }; // Change as needed
      case "preparing":
        return {
          backgroundColor: "yellow",
          color: "black",
          display: "inline-block",
          padding: "8px 16px",
          borderRadius: "4px",
          fontSize: "14px",
          fontWeight: "bold",
        }; // Change as needed
      case "completed":
        return {
          backgroundColor: "lightgreen",
          color: "black",
          display: "inline-block",
          padding: "8px 16px",
          borderRadius: "4px",
          fontSize: "14px",
          fontWeight: "bold",
        }; // Change as needed
      case "cancelled":
        return {
          backgroundColor: "red",
          color: "white",
          display: "inline-block",
          padding: "8px 16px",
          borderRadius: "4px",
          fontSize: "14px",
          fontWeight: "bold",
        };
      default:
        return {}; // Default or change as needed
    }
  };

  return (
    <div className={classes.checkOutFinal}>
      <ButtonAppBar turnOff />
      <Box className={classes.Checkoutbook}>
        {cartItems.length === 0 &&
        (!currentOrderId ||
          (currentOrderId &&
            currentOrder &&
            currentOrder.status === "completed")) ? (
          <>
            <Typography className={classes.para}>Cart Details</Typography>
            <Box className={classes.Checkoutcontent1}>
              <Typography sx={{ fontFamily: "Bubblegum Sans" }}>
                No Items In Cart
              </Typography>
              <Button
                sx={{ fontFamily: "Bubblegum Sans" }}
                onClick={() => navigate(`/items?${queryString}`)}
              >
                Add Items
              </Button>
            </Box>
          </>
        ) : (
          <>
            {tableNo === "table-takeaway" &&
            app.table?.data?.tableNo === "take-away" &&
            currentOrder ? null : (
              <Box className={classes.Checkoutcontent}>
                <Typography className={classes.para}>Cart Details</Typography>
                {cartItems.length > 0 ? (
                  cartItems.map((item) => (
                    <OrderDetails
                      key={item.id}
                      item={item}
                      addItemQty={addItemQty}
                      removeItemQty={removeItemQty}
                    />
                  ))
                ) : (
                  <Box sx={{ textAlign: "center" }}>
                    <Typography>No Items In Cart</Typography>
                    <Button onClick={() => navigate(`/items?${queryString}`)}>
                      Add Items
                    </Button>
                  </Box>
                )}
              </Box>
            )}

            <Box className={classes.Checkoutcontent}>
              <Divider />
              {tableNo === "table-takeaway" &&
              app.table?.data?.tableNo === "take-away"
                ? null
                : currentOrder &&
                  currentOrder.orderedItems && (
                    <Typography className={classes.para}>
                      Sub Order Details
                    </Typography>
                  )}
              {currentOrder &&
                currentOrder.orderedItems
                  .slice() // Make a copy to avoid mutating the original array
                  .reverse() // Reverse the copied array
                  .map((subOrder, i) => (
                    <Card
                      key={subOrder.timestamp}
                      sx={{
                        margin: "5px",
                      }}
                    >
                      {tableNo === "table-takeaway" &&
                      app.table?.data?.tableNo === "take-away" ? (
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "10px",
                          }}
                        >
                          <span>Take-Away Order Details</span>
                          <span style={getStatusStyle(subOrder.status)}>
                            {subOrder.status}
                          </span>
                        </Typography>
                      ) : (
                        <>
                          <Typography
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "10px",
                            }}
                          >
                            <span>
                              Suborder: #{currentOrder.orderedItems.length - i}
                            </span>
                            <span style={getStatusStyle(subOrder.status)}>
                              {subOrder.status}
                            </span>
                          </Typography>
                        </>
                      )}

                      {subOrder?.items &&
                        subOrder?.items.map((item) => (
                          <OrderDetails
                            key={item.id}
                            item={item}
                            addItemQty={addItemQty}
                            removeItemQty={removeItemQty}
                            type="suborder"
                          />
                        ))}
                    </Card>
                  ))}
            </Box>

            <Divider />
            <Box className={classes.subtotal}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <p className={classes.sub}>Subtotal</p>
                <p className={classes.Rm}>RM {totalAmount}</p>
              </Stack>
              {tableNo === "table-takeaway" &&
              app.table?.data?.tableNo === "take-away" ? (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <p className={classes.Fees}>Packaging Charges</p>
                  <p className={classes.FeesRm}>
                    RM {settings.price_details.packaging_charges.toFixed(2)}
                  </p>
                </Stack>
              ) : null}
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <p className={classes.Tax}>Service Charge</p>
                <p className={classes.TaxRm}>RM {serviceCharge}</p>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <p className={classes.Tax}>Tax (SST {taxPercentage}%)</p>
                <p className={classes.TaxRm}>RM {taxAmount}</p>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={classes.totalamount}
              >
                <p className={classes.sub}>Total (incl. SST)</p>
                <p className={classes.TotalRm}>RM {totalAmountWithTax}</p>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={classes.totalamount}
              >
                <p className={classes.Total}>Total (incl. SST) - Rounded</p>
                <p className={classes.TotalRm}>
                  RM {totalAmountWithTaxRoundOff}
                </p>
              </Stack>
              {currentOrder?.discountAmount &&
                currentOrder?.discountPercentage &&
                currentOrder?.discountedTotalAmount && (
                  <>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      className={classes.totalamount}
                    >
                      <p className={classes.Total}>
                        Discount ({currentOrder?.discountPercentage})%
                      </p>
                      <p className={classes.TotalRm}>
                        RM {currentOrder?.discountAmount}
                      </p>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      className={classes.totalamount}
                    >
                      <p className={classes.Total}>
                        Discounted Total - Rounded
                      </p>
                      <p className={classes.TotalRm}>
                        RM {currentOrder?.discountedTotalAmount}
                      </p>
                    </Stack>
                  </>
                )}
            </Box>
          </>
        )}
      </Box>

      <div className={classes.confirmOrder}>
        {tableNo === "table-takeaway" &&
        app?.table?.data?.tableNo === "take-away" &&
        cartItems.length > 0 &&
        !currentOrder ? (
          <TakeAwayButton
            handleTakeAwayOrder={handleConfirmOrder}
            tableDetail={app.table}
          />
        ) : tableNo !== "table-takeaway" &&
          app?.table?.data?.tableNo !== "take-away" &&
          cartItems.length > 0 &&
          currentOrderId &&
          currentOrder ? (
          <DineButton
            handleConfirmOrder={handleUpdateOrder}
            tableDetail={app.table}
            user={app.user}
            btnType="update"
            tableNo={tableNo}
            type={type}
          />
        ) : (
          tableNo !== "table-takeaway" &&
          app?.table?.data?.tableNo !== "take-away" &&
          cartItems.length > 0 && (
            // <UpdateOrder
            //   handleUpdateOrder={handleUpdateOrder}
            //   tableDetail={app.table}
            // />
            <DineButton
              handleConfirmOrder={handleConfirmOrder}
              tableDetail={app.table}
              user={app.user}
              btnType="confirm"
              tableNo={tableNo}
              type={type}
            />
          )
        )}
        {/* {cartItems.length > 0 && !currentOrderId && !currentOrder ? (
          <ConfirmOrder
            handleConfirmOrder={handleConfirmOrder}
            tableDetail={app.table}
          />
        ) : (
          cartItems.length > 0 &&
          currentOrderId &&
          currentOrder && (
            <UpdateOrder
              handleUpdateOrder={handleUpdateOrder}
              tableDetail={app.table}
            />
          )
        )} */}
      </div>
    </div>
  );
};

export default Checkout;
