import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
// import DirectionsIcon from "@mui/icons-material/Directions";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ViewListIcon from "@mui/icons-material/ViewList";
import { cookieOptions, isLocalStorageSupports } from "../utils";
import Cookies from "js-cookie";

const Searchbar = ({
  itemDisplayType,
  setItemDisplayType,
  search,
  setSearch,
  handleSearch,
}) => {
  const handleDisplayType = (type) => {
    setItemDisplayType(type);

    if (isLocalStorageSupports()) {
      localStorage.setItem("cardType", type);
    } else {
      Cookies.set("cardType", type, cookieOptions);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    handleSearch();
  };

  return (
    <Paper
      component="form"
      onSubmit={handleSubmit}
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search"
        inputProps={{ "aria-label": "search" }}
        value={search}
        onChange={handleSearch}
      />
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton
        sx={{ p: "10px" }}
        aria-label="menu"
        onClick={() => handleDisplayType("card")}
      >
        <DashboardIcon color={itemDisplayType === "card" ? "info" : ""} />
      </IconButton>
      <IconButton
        sx={{ p: "10px" }}
        aria-label="directions"
        onClick={() => handleDisplayType("list")}
      >
        <ViewListIcon color={itemDisplayType === "list" ? "info" : ""} />
      </IconButton>
    </Paper>
  );
};

export default React.memo(Searchbar);
