import { combineReducers } from "@reduxjs/toolkit";
import appReducer from "./appSlice";
import itemReducer from "./itemSlice";
import cartReducer from "./cartSlice";
import { apiSlice } from "../api/apiSlice";

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  app: appReducer,
  item: itemReducer, // not using as of now
  cart: cartReducer,
});

export default rootReducer;
