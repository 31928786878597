import { Button, Stack } from "@mui/material";
import classes from "./DineButton.module.css";

const TakeAwayButton = ({ handleTakeAwayOrder, tableDetail }) => {
  const takeAwayHandler = () => {
    if (
      window.confirm(
        "Order placed!!! Please proceed with payment to start preparing the order."
      )
    ) {
      handleTakeAwayOrder();
    }
  };

  return (
    <>
      <Stack
        display="flex"
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        className={classes.addingbar}
      >
        <Stack
          display="flex"
          direction="row"
          justifyContent="start"
          alignItems="center"
        >
          <Button
            variant="text"
            className={classes.confirm}
            onClick={takeAwayHandler}
          >
            Place Take-Away Order
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default TakeAwayButton;
