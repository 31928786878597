import * as React from "react";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import SelectDropDown from "../../Reusable/SelectDropDown";

export default function ButtonAppBar({
  category,
  categoryList,
  handleChange,
  disabled,
  turnOff,
}) {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Use navigate(-1) to navigate to the previous page
  };
  const appbar = {
    background: "#fff",
  };

  const toolbar = {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "5px",
    background: "#000",
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={appbar}>
        <Toolbar sx={toolbar}>
          <IconButton
            size="small"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleGoBack}
          >
            <ArrowBackIosNewIcon sx={{ color: "#b68e1d", fontSize: "18px" }} />
          </IconButton>
          {!turnOff && (
            <SelectDropDown
              disabled={disabled}
              category={category}
              categoryList={categoryList}
              handleChange={handleChange}
            />
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
