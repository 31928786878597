import * as React from "react";
import Grid from "@mui/material/Grid";
import OrderCard from "./OrderCard";
import { Stack } from "@mui/material";

const OrderColumnGrid = ({
  itemsData,
  handleClick,
  addNewItemToCart,
  removeItemFromCart,
  addItemQty,
  removeItemQty,
  cartItems,
}) => {
  return (
    <Stack sx={{ flexGrow: 1, height: "100vh" }}>
      <Grid container spacing={2}>
        {itemsData?.map((item) => (
          <Grid item xs={6} key={item.id}>
            <OrderCard
              item={item}
              handleClick={handleClick}
              addNewItemToCart={addNewItemToCart}
              removeItemFromCart={removeItemFromCart}
              addItemQty={addItemQty}
              removeItemQty={removeItemQty}
              cartItems={cartItems}
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default React.memo(OrderColumnGrid);
