import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import classes from "./OrderCard.module.css";
import { IconButton } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

const OrderCard = ({
  item,
  handleClick,
  addNewItemToCart,
  removeItemFromCart,
  addItemQty,
  removeItemQty,
  cartItems,
}) => {
  const [noOfItems, setNoOfItems] = useState(0);

  useEffect(() => {
    const currentItem = cartItems.find((cItem) => cItem.id === item.id);
    // console.log("item from order card",cartItems)
    //&& currentItem.status !== "booked" || currentItem.status !== "preparing" || currentItem.status!=="completed"
    if (currentItem) {
      setNoOfItems(currentItem.quantity);
    } else {
      setNoOfItems(0);
    }
  }, [cartItems, item.id]);

  return (
    <>
      <Card sx={{ maxWidth: 345 }}>
        {/* {console.log("search from cloum grid",item.name)} */}
        <div onClick={() => handleClick(item)}>
          <CardMedia sx={{ height: 150 }} image={item.imgUrl} />
          <CardContent className={classes.content}>
            <Typography className={classes.cardtitle}>{item.name}</Typography>
          </CardContent>
        </div>
        <CardActions className={classes.Actions}>
          <Typography>
            <b className={classes.RM}>RM</b>{" "}
            <span className={classes.price}>{item.itemPrice}</span>
          </Typography>
          {noOfItems === 0 ? (
            <Button
              className={classes.add}
              variant="text"
              onClick={() => addNewItemToCart(item)}
            >
              add
            </Button>
          ) : (
            <>
              <IconButton onClick={() => removeItemQty(item)}>
                <RemoveIcon />
              </IconButton>
              <p className={classes.qty}>{noOfItems}</p>
              <IconButton onClick={() => addItemQty(item)}>
                <AddIcon />
              </IconButton>
            </>
          )}
        </CardActions>
      </Card>
    </>
  );
};

export default React.memo(OrderCard);
