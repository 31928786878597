import { Button, IconButton, Stack, Typography } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import classes from "./ItemsCount.module.css";

const ItemsCount = ({
  itemCount,
  handleAddItemQty,
  handleRemoveItemQty,
  handleAddNewItem,
  handleConfirm,
}) => {
  return (
    <div className={classes.itemCountWrapper}>
      {itemCount.quantity > 0 ? (
        <Stack
          display="flex"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.addingbar}
        >
          <Stack
            display="flex"
            direction="row"
            justifyContent="start"
            alignItems="center"
          >
            <IconButton onClick={handleRemoveItemQty}>
              <RemoveIcon className={classes.icon1} />
            </IconButton>
            <p>{itemCount.quantity}</p>
            <IconButton onClick={handleAddItemQty}>
              <AddIcon className={classes.icon2} />
            </IconButton>
          </Stack>
          {/* <Stack
              display="flex"
              direction="row"
              justifyContent="start"
              alignItems="center"
            >
              <Button
                variant="text"
                className={classes.confirm}
                onClick={handleConfirm}
              >
                Confirm
              </Button>
            </Stack> */}
          <Stack
            display="flex"
            direction="row"
            justifyContent="start"
            alignItems="center"
          >
            <Typography>
              <b className={classes.RM}>Rm</b>{" "}
              <span className={classes.price}>{itemCount.totalItemPrice}</span>
            </Typography>
          </Stack>
        </Stack>
      ) : (
        <Stack
          display="flex"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.addingbar}
          onClick={handleAddNewItem}
        >
          <div className={classes.icBtnContainer}>
            <Button variant="text" className={classes.confirm}>
              Add
            </Button>
          </div>
        </Stack>
      )}
    </div>
  );
};

export default ItemsCount;
