import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth, db } from "../services/firebase";
import { useEffect, useState } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { setIsLoading, setUser } from "../store/appSlice";

import { Stack } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import classes from "./Register.module.css";

const Register = ({ tableNo, type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("name", name, email, phoneNumber);
  };

  return (
    <div className={classes.bannerImg}>
      <div className={classes.innerSection}>
        <img
          className={classes.logo}
          src="/images/albarakahlogo.png"
          alt="no-img"
        />
        <div className={classes.shoplogodiv}>
          <img
            className={classes.shoplogo}
            src="/images/logo.png"
            alt="no-img"
          />
        </div>
        <h3 className={classes.loginheading}>Register</h3>

        <form className={`${classes.overallform} d-flex flex-wrap`}>
          <label className={classes.modalLabel} htmlFor="name">
            Enter Name
          </label>
          <input
            className={classes.mobileInput}
            id="name"
            type="name"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          <form style={{ marginTop: "20px" }}>
            <label className={classes.modalLabel} htmlFor="mail">
              Enter Email
            </label>
            <input
              className={classes.mobileInput}
              id="mail"
              type="mail"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </form>
          <form style={{ marginTop: "20px" }}>
            <label className={classes.modalLabel} htmlFor="mobile-number">
              Enter Mobile Number
            </label>
            <input
              className={classes.mobileInput}
              id="mobile-number"
              type="number"
              onChange={(e) => setPhoneNumber(e.target.value)}
              value={phoneNumber}
            />
          </form>

          <>
            <button
              onClick={handleSubmit}
              style={{
                width: "fit-content",
                margin: "auto",
                marginTop: "20px",
              }}
              className={classes.listBtn}
            >
              Register
            </button>
            <p className={classes.statusText}>
              If you already a user?
              <Link
                className={classes.register}
                to={`/?table-no=${tableNo}&type=${type}`}
              >
                Login
              </Link>{" "}
            </p>
          </>
        </form>
      </div>
    </div>
  );
};

export default Register;
