import React from "react";
import HTMLFlipBook from "react-pageflip";
import classes from "./Book.module.css";
import {
  arabMenuPics,
  drinksDessertsMenuPics,
  indianMenuPics,
} from "../utils/constants";

const Book = ({ category, book }) => {
  // const book = useRef();
  const data =
    category === "indian"
      ? indianMenuPics
      : category === "arab"
      ? arabMenuPics
      : drinksDessertsMenuPics;

  // Use a key to force remounting when the category changes
  const key = category;

  // https://www.npmjs.com/package/react-zoom-pan-pinch

  return (
    <>
      {data.length > 0 && (
        <HTMLFlipBook
          width={350}
          height={450}
          key={key}
          ref={book}
          useMouseEvents={false}
        >
          {data.map((pic, index) => (
            <div className={classes.page} key={index}>
              <img
                key={pic}
                src={pic}
                alt={pic}
                className={classes.bookImage}
              />
            </div>
          ))}
        </HTMLFlipBook>
      )}
      {/* <button onClick={() => book?.current?.pageFlip()?.flipPrev()}>
        Prev page
      </button>
      <button onClick={() => book?.current?.pageFlip()?.flipNext()}>
        Next page
      </button> */}
      {/* <p>Total Page: {book?.current?.pageFlip()?.getPageCount()}</p> */}
    </>
  );
};

export default Book;
