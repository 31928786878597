import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useGetSettingsQuery } from "../api/apiSlice";
import BackButton from "../Reusable/BackButton";
import SelectDropDown from "../Reusable/SelectDropDown";
import Book from "../Reusable/Book";
import classes from "./MenuBook.module.css";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useRef } from "react";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ButtonAppBar from "../components/Order/ButtonAppBar";

const MenuBook = ({ tableNo, type }) => {
  const book = useRef();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [category, setCategory] = useState(state?.category || "indian");
  const { data, isError, isFetching, isLoading } = useGetSettingsQuery();
  // console.log(category, data, isError, isFetching, isLoading);

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  const handleOrderNow = () => {
    const queryParams = { "table-no": tableNo, type: type };

    // Convert the object into a query string
    const queryString = new URLSearchParams(queryParams).toString();

    navigate(`/items?${queryString}`, { state: { category: category } });
  };

  return (
    <div className={classes.bookContainer}>
      <div className={classes.bookNav}>
        <BackButton />
        <SelectDropDown
          category={category}
          categoryList={data?.categories || []}
          handleChange={handleChange}
        />
        <motion.button
          whileHover={{ scale: 1.1 }}
          transition={{ stiffness: 500, type: "spring" }}
          className={classes.orderBtn}
          onClick={handleOrderNow}
        >
          Order Now
        </motion.button>
      </div>
      <TransformWrapper
        initialScale={1}
        initialPositionX={0}
        initialPositionY={0}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <div className={classes.zoomContainer}>
            <TransformComponent>
              <Book category={category} book={book} />
            </TransformComponent>
            {/* <div className="tools">
            <button onClick={() => zoomIn()}>+</button>
            <button onClick={() => zoomOut()}>-</button>
            <button onClick={() => resetTransform()}>x</button>
          </div> */}
            <div className={classes.arrowContainer}>
              <div className={classes.btnContainer}>
                <ArrowCircleLeftIcon
                  className={classes.arrow}
                  onClick={() => book?.current?.pageFlip()?.flipPrev()}
                />
                <button className={classes.zoomBtn} onClick={() => zoomIn()}>
                  +
                </button>
              </div>
              {/* <button className={classes.zoomBtn} onClick={() => resetTransform()}>x</button> */}
              <div className={classes.btnRightContainer}>
                <button className={classes.zoomBtn} onClick={() => zoomOut()}>
                  -
                </button>
                <ArrowCircleRightIcon
                  className={classes.arrow}
                  onClick={() => book?.current?.pageFlip()?.flipNext()}
                />
              </div>
            </div>
          </div>
        )}
      </TransformWrapper>

      {/* <BackButton /> */}
    </div>
  );
};

export default MenuBook;
