import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import classes from "./BasicModal.module.css";
import { useDispatch } from "react-redux";
import { setTableError } from "../store/appSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "2px solid #ffffff",
  boxShadow: 24,
  p: 2,
  borderRadius: 4,
};

export default function BasicModal({ open, title, desc, isClose }) {
  const dispatch = useDispatch();

  const handleClose = () => {
    if (isClose) {
      dispatch(
        setTableError({
          isClose: false,
          isTableError: false,
          isTableMessage: "",
        })
      );
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {isClose && (
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              sx={{ position: "absolute", top: 0, right: 10 }}
            >
              <CloseIcon />
            </IconButton>
          )}

          <div className={classes.modalHeader}>
            <p className={classes.headerText}>{title}</p>
          </div>
          <div className={classes.modalContent}>
            <p className={classes.desc}>{desc}</p>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
