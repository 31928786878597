const getSingaporeDate = () => {
  const singaporeTimezone = "Asia/Singapore";
  const currentDateInSingapore = new Date().toLocaleString("en-US", {
    timeZone: singaporeTimezone,
  });
  const singaporeDate = new Date(currentDateInSingapore);
  return singaporeDate;
};

const isLocalStorageSupports = () => {
  try {
    return "localStorage" in window && window["localStorage"] !== null;
  } catch (e) {
    return false;
  }
};

const cookieOptions = {
  // path: "/",
  expires: 1,
  domain: "albarakahrestaurant.com",
  secure: false, // in dev
  // sameSite: true,
};

const checkBrowser = () => {
  // Get the user-agent string
  let userAgentString = navigator.userAgent;

  // Detect Chrome
  let chromeAgent = userAgentString.indexOf("Chrome") > -1;

  // Detect Internet Explorer
  let IExplorerAgent =
    userAgentString.indexOf("MSIE") > -1 || userAgentString.indexOf("rv:") > -1;

  // Detect Firefox
  let firefoxAgent = userAgentString.indexOf("Firefox") > -1;

  // Detect Safari
  let safariAgent = userAgentString.indexOf("Safari") > -1;

  // Discard Safari since it also matches Chrome
  if (chromeAgent && safariAgent) safariAgent = false;

  // Detect Opera
  let operaAgent = userAgentString.indexOf("OP") > -1;

  // Discard Chrome since it also matches Opera
  if (chromeAgent && operaAgent) chromeAgent = false;

  // document.querySelector(".output-safari").textContent = safariAgent;
  // document.querySelector(".output-chrome").textContent = chromeAgent;
  // document.querySelector(".output-ie").textContent = IExplorerAgent;
  // document.querySelector(".output-opera").textContent = operaAgent;
  // document.querySelector(".output-firefox").textContent = firefoxAgent;

  // Return the detected browser agent
  if (chromeAgent) {
    return "chrome";
  } else if (IExplorerAgent) {
    return "unknown"; // "ie"
  } else if (firefoxAgent) {
    return "firefox";
  } else if (safariAgent) {
    return "safari";
  } else if (operaAgent) {
    return "unknown"; // "ie"
  } else {
    return "unknown";
  }
};

export {
  getSingaporeDate,
  isLocalStorageSupports,
  cookieOptions,
  checkBrowser,
};
