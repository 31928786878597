import { Button, Stack, Typography } from "@mui/material";
import classes from "./CheckoutBar.module.css";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { setTableError } from "../../store/appSlice";

const CheckoutBar = ({ cartItems, tableNo, type }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const calculateTotals = useMemo(() => {
    let totalAmount = 0;
    let totalQuantity = 0;
    cartItems.forEach((item) => {
      totalAmount += item.totalPrice;
      totalQuantity += item.quantity;
    });
    return {
      totalAmount: totalAmount.toFixed(2),
      totalQuantity: totalQuantity,
    };
  }, [cartItems]);

  const { totalAmount, totalQuantity } = calculateTotals;

  const queryParams = { "table-no": tableNo, type: type };
  const queryString = new URLSearchParams(queryParams).toString();

  const handleCheckout = () => {
    // console.log("tal", tableNo, typeof tableNo);
    if (tableNo === "null" || tableNo === "undefined" || tableNo === "") {
      dispatch(
        setTableError({
          isClose: true,
          canPlaceOrder: false,
          isTableError: true,
          isTableMessage:
            "Please scan the table inside restuarant and don't use the link directly to place order, if QR code not working, please inform admin!!!",
        })
      );
    } else {
      // console.log("table: ", tableNo);
      navigate(`/checkout?${queryString}`);
    }
  };

  return (
    <div className={classes.checkoutWrapper}>
      <Stack
        display="flex"
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        className={classes.addingbar}
      >
        <Stack
          display="flex"
          direction="row"
          justifyContent="start"
          alignItems="center"
        >
          {/* <IconButton>
            <RemoveIcon className={classes.icon1} />
          </IconButton> */}
          <p className={classes.qty}>{totalQuantity}</p>
          {/* <IconButton>
            <AddIcon className={classes.icon2} />
          </IconButton> */}
        </Stack>
        <Stack
          display="flex"
          direction="row"
          justifyContent="start"
          alignItems="center"
        >
          <Button
            variant="text"
            onClick={handleCheckout}
            className={classes.confirm}
          >
            Checkout
          </Button>
        </Stack>
        <Stack
          display="flex"
          direction="row"
          justifyContent="start"
          alignItems="center"
        >
          <Typography>
            <b className={classes.RM}>RM</b>{" "}
            <span className={classes.price}>{totalAmount}</span>
          </Typography>
        </Stack>
      </Stack>
    </div>
  );
};

export default CheckoutBar;
