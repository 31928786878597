// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCav7I3h8aXPicfpHATsgrAUseIDiBoOio",
  authDomain: "albarakah-fusion-4afcb.firebaseapp.com",
  projectId: "albarakah-fusion-4afcb",
  storageBucket: "albarakah-fusion-4afcb.appspot.com",
  messagingSenderId: "636357539132",
  appId: "1:636357539132:web:e5c19c05edcf7a0188da39",
  measurementId: "G-DSPRTGVG8T",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);

export { app, analytics, db, auth };
