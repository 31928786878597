import React from "react";
// import { ErrorBoundary } from "react-error-boundary";
import Router from "./routes";
import "./style.css";
import BasicModal from "./Reusable/BasicModal";
import { useSelector } from "react-redux";
import { selectTable } from "./store/appSlice";
import MobileOTPModal from "./Reusable/MobileOTPModal";
import Spinner from "./Reusable/Spinner";

function App() {
  const logError = (error, info) => {
    // Do something with the error, e.g. log to an external API
    // console.log("error", error);
    // console.log("info", info);
  };
  const { app } = useSelector(selectTable);
  // console.log("App: ", app.user);

  return (
    // <ErrorBoundary
    //   // FallbackComponent={<p>Some error, please try after sometime</p>}
    //   onError={logError}
    // >
    <>
      <Router />
      <BasicModal
        open={app?.table?.isTableError}
        title={"Action Denied"}
        desc={app?.table?.isTableMessage}
        isClose={app?.table?.isClose}
      />
      <Spinner />
      {/* {!app.user.id && !app.user.phone ? <MobileOTPModal /> : null} */}
    </>
    // </ErrorBoundary>
  );
}

export default App;
