const arabMenuPics = [
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/arab_menu_pic_1_byukfh.jpg", // 1
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/arab_menu_pic_12_jcuuwr.jpg", // 2
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/arab_menu_pic_20_wyn4mb.jpg", // 3 - salad
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/arab_menu_pic_21_c03iwo.jpg", // 4
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/arab_menu_pic_22_om58mq.jpg", // 5
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/arab_menu_pic_23_fk8upt.jpg", // 6
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/arab_menu_pic_24_vaelhd.jpg", // 7
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/arab_menu_pic_25_qet6vu.jpg", // 8 - dessert
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/arab_menu_pic_26_xggyp3.jpg", // 9
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/arab_menu_pic_2_satfq9.jpg", // 10 - main course
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/arab_menu_pic_3_bqj0rm.jpg", // 11
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/arab_menu_pic_4_vouavn.jpg", // 12
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/arab_menu_pic_5_yacz4r.jpg", // 13
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/arab_menu_pic_6_dtkf0c.jpg", // 14
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/arab_menu_pic_7_d6f2gn.jpg", // 15
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/arab_menu_pic_8_vfyv5f.jpg", // 16
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/arab_menu_pic_9_cih4zw.jpg", // 17
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/arab_menu_pic_10_gofps6.jpg", // 18
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/arab_menu_pic_11_puw4r1.jpg", // 19
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/arab_menu_pic_13_dmridk.jpg", // 20
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/arab_menu_pic_14_iqgya5.jpg", // 21
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/arab_menu_pic_15_rtxzwy.jpg", // 20
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/arab_menu_pic_16_hls7br.jpg", // 23 - lamba tikka
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/arab_menu_pic_17_n4qf86.jpg", // 24
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/arab_menu_pic_18_qdwwrz.jpg", // 25
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/arab_menu_pic_19_orx4sm.jpg", // 26
];

const indianMenuPics = [
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/indian-menu-pic_1_cvjbu5.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/indian-menu-pic_2_dy6aar.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/indian-menu-pic_3_wmigkc.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/indian-menu-pic_4_yijako.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/indian-menu-pic_5_ahmvch.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/indian-menu-pic_6_m5p9qv.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/indian-menu-pic_7_wewttx.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/indian-menu-pic_8_k9dg9b.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/indian-menu-pic_9_cxkfmd.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/indian-menu-pic_10_jble7i.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/indian-menu-pic_11_te90bu.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/indian-menu-pic_12_k2f2a8.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/indian-menu-pic_13_razhpf.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/indian-menu-pic_14_i10sfs.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/indian-menu-pic_15_dbny95.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/indian-menu-pic_16_n15x23.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/indian-menu-pic_17_hk1ph9.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/indian-menu-pic_18_cwwvqb.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/indian-menu-pic_19_whvlde.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/indian-menu-pic_20_euvfse.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/indian-menu-pic_21_mb0xec.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/indian-menu-pic_22_leflyo.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/indian-menu-pic_23_trhc13.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/indian-menu-pic_24_a0myoe.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/indian-menu-pic_25_uqdved.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/indian-menu-pic_26_r76xgm.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/indian-menu-pic_27_vxjebj.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/indian-menu-pic_28_avfegr.jpg",
];

const drinksDessertsMenuPics = [
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/drinks-desserts-menu-pic_1_eucmao.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/drinks-desserts-menu-pic_7_vhxe5i.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/drinks-desserts-menu-pic_8_lyo4bf.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/drinks-desserts-menu-pic_9_cmvbn6.jpg",
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/drinks-desserts-menu-pic_10_cpskcs.jpg", // milkshae
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/drinks-desserts-menu-pic_11_jrxm7a.jpg", // mocktails
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/drinks-desserts-menu-pic_12_qvmu8d.jpg", // drink mocktails
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/drinks-desserts-menu-pic_13_me3pfo.jpg", // healthy drinks
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/drinks-desserts-menu-pic_14_kjoeg6.jpg", // juice cup
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/drinks-desserts-menu-pic_2_liow0j.jpg", // fresh juice
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/drinks-desserts-menu-pic_3_jzljmm.jpg", // coffee
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/drinks-desserts-menu-pic_4_hbzg1e.jpg", // coffee selection
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/drinks-desserts-menu-pic_5_ltsihg.jpg", // blended
  "https://res.cloudinary.com/ddxqcxkhw/image/upload/drinks-desserts-menu-pic_6_dwfqj7.jpg",
];

export { arabMenuPics, indianMenuPics, drinksDessertsMenuPics };
