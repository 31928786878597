import ScrollingTab from "../Reusable/ScrollingTab";
import Searchbar from "../Reusable/Searchbar";
// import BackButton from "../Reusable/BackButton";
import OrderListCard from "../components/Order/OrderListCard";
import { Box, Stack, Typography } from "@mui/material";
// import OrderCard from "../components/Order/OrderCard";
import OrderColumnGrid from "../components/Order/OrderColumnGrid";
import classes from "./Order.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
// import SelectDropDown from "../Reusable/SelectDropDown";
import { useGetItemsQuery, useGetSettingsQuery } from "../api/apiSlice";
import { useDispatch, useSelector } from "react-redux";
import { setItem } from "../store/itemSlice";
import ButtonAppBar from "../components/Order/ButtonAppBar";
// import ItemsCount from "../components/Order/ItemsCount";
import CheckoutBar from "../components/Order/CheckoutBar";
import {
  addQtyToCart,
  addToCart,
  removeFromCart,
  removeQtyFromCart,
  selectCartItems,
} from "../store/cartSlice";
import { isLocalStorageSupports, cookieOptions } from "../utils";
import Cookies from "js-cookie";
import { selectTable, setTableError } from "../store/appSlice";

const Order = ({ tableNo, type }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { cartItems } = useSelector(selectCartItems);
  const { app } = useSelector(selectTable);
  const {
    data: settingsData,
    isError: settingsIsError,
    isFetching: settingsIsFetching,
    isLoading: settingsIsLoading,
  } = useGetSettingsQuery();
  // console.log(
  //   "settings",
  //   settingsData,
  //   settingsIsError,
  //   settingsIsFetching,
  //   settingsIsLoading
  // );
  const [showItem, setShowItem] = useState([]);
  const [category, setCategory] = useState(state?.category || "indian");
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const {
    data: itemsData,
    isError: itemsIsError,
    isFetching: itemsIsFetching,
    isLoading: itemsIsLoading,
  } = useGetItemsQuery(category);

  // console.log(
  //   "Items: ",
  //   itemsData,
  //   itemsIsError,
  //   itemsIsFetching,
  //   itemsIsLoading
  // );

  // console.log("cartItems: ", cartItems);

  const [itemDisplayType, setItemDisplayType] = useState("card"); // card || list

  useEffect(() => {
    let cardType;
    if (isLocalStorageSupports()) {
      cardType = localStorage.getItem("cardType");
    } else {
      cardType = Cookies.get("cardType", cookieOptions);
    }
    // store.get("cardType");
    // console.log("cardType: ", cardType);
    if (cardType) {
      setItemDisplayType(cardType);
    }
  }, []);

  // useEffect(() => {
  //   setShowItem(searchResults);
  //   const showProducts = sortedItems.map((sItem) => {
  //     return sItem.items;
  //   });
  //   !search && setShowItem(showProducts);
  // }, [searchResults, search]);

  const categoryDetail = useMemo(() => {
    return settingsData?.categories.find((cat) => cat.id === category);
  }, [category, settingsData?.categories]);

  // we can't sort with chain with map, bcs sort will change the array, so we need new variable and map with that, else will get error.
  // const sortedSubcategories = categoryDetail?.subcategories
  //   ? [...categoryDetail.subcategories].sort((a, b) => a.orderNo - b.orderNo)
  //   : [];
  const sortedSubcategories = useMemo(() => {
    if (categoryDetail?.subcategories) {
      return [...categoryDetail.subcategories].sort(
        (a, b) => a.orderNo - b.orderNo
      );
    }
    return [];
  }, [categoryDetail?.subcategories]);

  const sortedItems = useMemo(() => {
    if (itemsData && sortedSubcategories) {
      const formattedItems = sortedSubcategories.map((subcat) => ({
        subcat: subcat,
        items: itemsData.filter((item) => item.subCategory.id === subcat.id),
      }));
      // console.log("formattedItems: ", formattedItems);
      return formattedItems;
    }
    return [];
  }, [itemsData, sortedSubcategories]);

  // useEffect(() => {
  //   setSearchResults(sortedItems);
  // }, [sortedItems]);

  // const handleSearch = debounce((e) => {
  //   const searchTerm = e.target.value;
  //   // console.log("searchTerm: ", searchTerm, searchTerm.length);
  //   setSearch(searchTerm);
  //   if (!searchTerm) {
  //     setSearchResults(sortedItems);
  //   } else if (searchTerm.length >= 3) {
  //     // console.log("sortedItem: ", searchTerm, sortedItems);

  //     // approach 1 - with normal looping
  //     // const allItems = [...sortedItems];

  //     // const filteredResults = [];

  //     // allItems.forEach((subcatItem) => {
  //     //   const result = subcatItem.items.filter((product) =>
  //     //     product.name.toLowerCase().includes(searchTerm.toLowerCase())
  //     //   );

  //     //   filteredResults.push({
  //     //     subcat: subcatItem.subcat,
  //     //     items: result,
  //     //   });
  //     // });

  //     // approach 2 - using functional programming for better readability and performance
  //     const filteredResults = sortedItems
  //       .map((subcatItem) => ({
  //         subcat: subcatItem.subcat,
  //         items: subcatItem.items.filter((product) =>
  //           product.name.toLowerCase().includes(searchTerm)
  //         ),
  //       }))
  //       .filter(({ items }) => items.length > 0);

  //     setSearchResults(filteredResults);
  //   }
  // }, 500);

  const handleSearch = (e) => {
    if (e) {
      const searchTerm = e.target.value;
      setSearch(searchTerm.toLowerCase());
    }
  };

  // // debounce function ensures that the search function is only called once the user stops typing for a certain duration (500ms in this case).
  // function debounce(func, delay) {
  //   let timeoutId;
  //   return function (...args) {
  //     clearTimeout(timeoutId);
  //     timeoutId = setTimeout(() => func.apply(this, args), delay);
  //   };
  // }

  const filteredResults = useMemo(() => {
    if (search.length >= 3) {
      const results = sortedItems
        .map((subcatItem) => ({
          subcat: subcatItem.subcat,
          items: subcatItem.items.filter((product) =>
            product.name.toLowerCase().includes(search)
          ),
        }))
        .filter(({ items }) => items.length > 0);
      return results;
    }
    return sortedItems; // Return original list if search term is too short
  }, [search, sortedItems]);

  const handleChange = useCallback((event) => {
    setCategory(event.target.value);
  }, []);

  const queryParams = { "table-no": tableNo, type: type };
  const queryString = new URLSearchParams(queryParams).toString();

  const handleClick = (item) => {
    dispatch(setItem(item));
    navigate(`/item/${item.id}/item-desc?${queryString}`, {
      state: { category: category },
    });
  };

  const addNewItemToCart = (item) => {
    dispatch(addToCart(item));
    // if (app.table.canPlaceOrder) {
    //   dispatch(addToCart(item));
    // } else {
    //   dispatch(
    //     setTableError({
    //       isClose: true,
    //       isTableError: true,
    //       isTableMessage: "Please scan from restuarant to place orders",
    //       canPlaceOrder: false,
    //     })
    //   );
    // }
  };

  const removeItemFromCart = (item) => {
    dispatch(removeFromCart(item));
  };

  const addItemQty = (item) => {
    dispatch(addQtyToCart(item));
  };

  const removeItemQty = (item) => {
    dispatch(removeQtyFromCart(item));
  };

  return (
    <>
      <ButtonAppBar
        category={category}
        categoryList={settingsData?.categories || []}
        handleChange={handleChange}
      />
      <ScrollingTab sortedItems={filteredResults} />
      <Searchbar
        itemDisplayType={itemDisplayType}
        setItemDisplayType={setItemDisplayType}
        handleSearch={handleSearch}
      />
      <Box className={classes.book}>
        {filteredResults?.map(
          (sItem) =>
            sItem.items.length > 0 && (
              <div key={sItem.subcat.id}>
                <Typography className={classes.menu} id={sItem.subcat.id}>
                  {sItem.subcat.name}
                  {/* {search ? searchResults.every(arr => arr.subCategory.includes(sItem.subcat.id))  && sItem.subcat.name :sItem.subcat.name } */}
                </Typography>
                {/* {console.log("arrFilter", arrFilter)} */}
                <Box className={classes.overallcard_order}>
                  {itemDisplayType === "list" ? (
                    // <div key="OrderList" style={{ height: "100vh" }}>

                    <div key="OrderList">
                      {/* {itemsData?.map((item) => ( */}
                      {/* {search
                        ? arrFilter[0]?.map((item) => {
                            return (
                              <OrderListCard
                                key={item.id}
                                item={item}
                                handleClick={handleClick}
                                addNewItemToCart={addNewItemToCart}
                                removeItemFromCart={removeItemFromCart}
                                addItemQty={addItemQty}
                                removeItemQty={removeItemQty}
                                cartItems={cartItems}
                              />
                            );
                          })
                        : sItem.items?.map((item) => {
                            return (
                              <OrderListCard
                                key={item.id}
                                item={item}
                                handleClick={handleClick}
                                addNewItemToCart={addNewItemToCart}
                                removeItemFromCart={removeItemFromCart}
                                addItemQty={addItemQty}
                                removeItemQty={removeItemQty}
                                cartItems={cartItems}
                              />
                            );
                          })} */}
                      {sItem.items?.map((item) => {
                        return (
                          <OrderListCard
                            key={item.id}
                            item={item}
                            handleClick={handleClick}
                            addNewItemToCart={addNewItemToCart}
                            removeItemFromCart={removeItemFromCart}
                            addItemQty={addItemQty}
                            removeItemQty={removeItemQty}
                            cartItems={cartItems}
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <Stack
                      className={classes.OrderColumnGrid}
                      key="OrderColumn"
                    >
                      <OrderColumnGrid
                        itemsData={sItem.items}
                        handleClick={handleClick}
                        addNewItemToCart={addNewItemToCart}
                        removeItemFromCart={removeItemFromCart}
                        addItemQty={addItemQty}
                        removeItemQty={removeItemQty}
                        cartItems={cartItems}
                        // search={search}
                        // searchResults={searchResults}
                      />
                    </Stack>
                  )}
                </Box>
              </div>
            )
        )}
      </Box>
      <div className={classes.checkOutPosition}>
        {cartItems.length > 0 && (
          <CheckoutBar cartItems={cartItems} type={type} tableNo={tableNo} />
        )}
      </div>
    </>
  );
};

export default Order;
