import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  app: {
    isLoading: false,
    user: {
      uid: "",
      phone: "",
      currentOrderId: null,
      name: "",
      email: "",
    },
    table: {
      data: null,
      isTableError: false,
      isTableMessage: "",
      isClose: false,
      canPlaceOrder: false,
    },
  },
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setTableDetail: (state, action) => {
      const payload = action.payload;
      console.log("setTableDetail: ", payload);
      state.app.table = {
        ...state.app.table,
        data: payload.data,
        isTableError: false,
        isTableMessage: "",
        canPlaceOrder: payload.canPlaceOrder,
      };
    },
    setTableError: (state, action) => {
      const payload = action.payload;
      state.app.table = {
        data: null,
        isClose: payload.isClose,
        isTableError: payload.isTableError,
        isTableMessage: payload.isTableMessage,
        canPlaceOrder: payload.canPlaceOrder,
      };
    },
    setUser: (state, action) => {
      const payload = action.payload;
      state.app.user = {
        id: payload.id,
        phone: payload.phone,
        currentOrderId: payload.currentOrderId,
        name: payload.name || "",
        email: payload.email || "",
      };
    },
    setIsLoading: (state, action) => {
      state.app.isLoading = action.payload;
    },
  },
});

export const { setTableError, setTableDetail, setUser, setIsLoading } =
  appSlice.actions;

export const selectTable = (state) => state.app;
export const selectIsLoading = (state) => state.app.app.isLoading;
export const selectUser = (state) => state.app.app.user;

export default appSlice.reducer;
