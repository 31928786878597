import { Box, Stack } from "@mui/material";
import ItemsDesc from "../components/Order/ItemsDesc";
import ItemsCount from "../components/Order/ItemsCount";
import { useDispatch, useSelector } from "react-redux";
import { selectItem, setItem } from "../store/itemSlice";
import { useLocation, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../services/firebase";
import ButtonAppBar from "../components/Order/ButtonAppBar";
import { useGetSettingsQuery } from "../api/apiSlice";
import {
  addQtyToCart,
  addToCart,
  removeFromCart,
  removeQtyFromCart,
  selectCartItems,
  updateCartItem,
} from "../store/cartSlice";

const OrderDesc = ({ tableNo, type }) => {
  const { id } = useParams();
  const { item } = useSelector(selectItem);
  const { cartItems } = useSelector(selectCartItems);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [category, setCategory] = useState(state?.category || "indian");
  const {
    data: settingsData,
    isError: settingsIsError,
    isFetching: settingsIsFetching,
    isLoading: settingsIsLoading,
  } = useGetSettingsQuery();
  const [itemCount, setItemCount] = useState({
    quantity: 0,
    totalItemPrice: 0,
  });

  const currentItem = useMemo(
    () => cartItems.find((cItem) => cItem.id === item.id) || {},
    [cartItems, item.id]
  );

  useEffect(() => {
    const getItemById = async (docId) => {
      try {
        const collection = "items";
        const docRef = doc(db, collection, docId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          // console.log("item: ", data);
          dispatch(setItem(data));
          setCategory(data.category.id);
        } else {
          const error = {
            name: "invalid_id",
            message: `No such document - path: ${collection}/${docId}`,
            data: "",
          };
          console.log("warning: ", error);
        }
      } catch (e) {
        const error = {
          name: "getItemById",
          message: e?.message || "error",
          data: e,
        };
        console.log("error: ", error);
      }
    };

    if (!item.id) {
      getItemById(id);
    }
  }, [dispatch, id, item.id]);

  useEffect(() => {
    setItemCount({
      quantity: currentItem.quantity || 0,
      totalItemPrice: currentItem.totalPrice || 0,
    });
  }, [currentItem]);

  const book = {
    border: "1px solid #ccc",
    maxHeight: "100vh",
    overflowY: "scroll",
  };
  const items = {
    minHeight: "89vh",
  };

  const handleAddItemQty = () => {
    setItemCount((prevState) => {
      const { quantity } = prevState;
      const updatedQty = quantity + 1;
      return {
        quantity: updatedQty,
        totalItemPrice: updatedQty * item.itemPrice,
      };
    });
    dispatch(addQtyToCart(item));
  };

  const handleRemoveItemQty = () => {
    dispatch(removeQtyFromCart(item));
    setItemCount((prevState) => {
      const { quantity } = prevState;
      const updatedQty = quantity - 1;
      return {
        quantity: updatedQty,
        totalItemPrice: updatedQty * item.itemPrice,
      };
    });
  };

  const handleAddNewItem = () => {
    setItemCount({
      quantity: 1,
      totalItemPrice: item.itemPrice,
    });
    dispatch(addToCart(item));
  };

  return (
    <>
      <ButtonAppBar
        category={category}
        categoryList={settingsData?.categories || []}
        // handleChange={handleChange} // disable here
        disabled
      />
      <Box sx={book}>
        <Stack sx={items}>
          <ItemsDesc item={item} />

          {/* <BackButton /> */}
        </Stack>
      </Box>
      <div style={{ position: "absolute", bottom: "0px", width: "414px" }}>
        <ItemsCount
          itemCount={itemCount}
          handleAddItemQty={handleAddItemQty}
          handleRemoveItemQty={handleRemoveItemQty}
          handleAddNewItem={handleAddNewItem}
          // handleConfirm={handleConfirm}
        />
      </div>
    </>
  );
};

export default OrderDesc;
