import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import classes from "./SelectDropDown.module.css";
import styled from "@emotion/styled";
import { InputBase, TextField, alpha } from "@mui/material";

export default function SelectDropDown({
  disabled,
  category,
  categoryList,
  handleChange,
}) {
  return (
    <Box sx={{ minWidth: 150 }} className={classes.from}>
      <FormControl fullWidth>
        <InputLabel
          id="demo-simple-select-label"
          sx={{
            color: "#b68e1d",
            fontFamily: "Bubblegum Sans",
            fontWeight: "700",
          }}
        >
          Menu
        </InputLabel>
        <Select
          sx={{
            background: "#fff",
            color: "#b68e1d",
            fontFamily: "Bubblegum Sans",
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={category}
          label="Menu"
          onChange={handleChange}
          disabled={disabled}
          inputProps={{
            style: {
              padding: "5px", // Reduce the padding
              color: "gold", // Text color
            },
          }}
        >
          {categoryList?.map((category) => (
            <MenuItem
              sx={{ color: "#b68e1d", fontFamily: "Bubblegum Sans" }}
              key={category.id}
              value={category.id}
            >
              {category.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
