import { Button, Stack } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import classes from "./DineButton.module.css";
import { useNavigate } from "react-router-dom";

const DineButton = ({
  handleConfirmOrder,
  tableDetail,
  user,
  btnType,
  tableNo,
  type,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <Stack
        display="flex"
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        className={classes.addingbar}
      >
        <Stack
          display="flex"
          direction="row"
          justifyContent="start"
          alignItems="center"
        >
          {user.id && user.phone ? (
            <Button
              variant="text"
              className={classes.confirm}
              disabled={tableDetail?.data?.status !== "accepted"}
              onClick={handleConfirmOrder}
            >
              {btnType === "confirm" ? "Confirm Order" : "Update Order"}
            </Button>
          ) : (
            <Button
              variant="text"
              className={classes.confirm}
              onClick={() => {
                navigate(`/?table-no=${tableNo}&type=${type}`);
              }}
            >
              Login to Place Order
            </Button>
          )}

          {user.id && user.phone && tableDetail?.data?.status !== "accepted" ? (
            <span>
              <Tooltip
                title="Admin approval required for placing order"
                placement="top"
              >
                <HelpIcon />
              </Tooltip>
            </span>
          ) : null}
        </Stack>
      </Stack>
    </>
  );
};

export default DineButton;
