import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "./ScrollingTab.module.css";
import { Link, useNavigate } from "react-router-dom";

const ScrollingTab = ({ sortedItems }) => {
  // console.log("sortedItems: ", sortedItems);
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    // console.log(newValue); // 0, 1, 2
    setValue(newValue);
  };

  const tabs = {
    fontSize: "14px",
    textTransform: "capitalize",
    // background: "#000",
    color: "#b68e1d",
    fontFamily: "Bubblegum Sans",
  };

  const handleCategory = (id) => {
    navigate(`{#${id}}`);
  };
  // // we can't sort with chain with map, bcs sort will change the array, so we need new variable and map with that, else will get error.
  // const sortedSubcategories = categoryDetail?.subcategories
  //   ? [...categoryDetail.subcategories].sort((a, b) => a.orderNo - b.orderNo)
  //   : [];

  return (
    <Box
      sx={{ maxWidth: { xs: "100%", sm: "100%" }, bgcolor: "background.paper" }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        sx={{
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
        {/* <Tab sx={tabs} label="All Menu" /> */}
        {sortedItems.map(
          (sItem) =>
            sItem.items.length > 0 && (
              <a href={`#${sItem.subcat.id}`} key={sItem.subcat.id}>
                <Tab
                  key={sItem.subcat.id}
                  sx={tabs}
                  label={sItem.subcat.name}
                />
              </a>
            )
        )}
      </Tabs>
    </Box>
  );
};

export default React.memo(ScrollingTab);
