import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth, db } from "../services/firebase";
import { useEffect, useState } from "react";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setIsLoading, setUser } from "../store/appSlice";
import classes from "./Login.module.css";
import { Stack } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

const Login = ({ tableNo, type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDet = useSelector(selectUser);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpPrompt, setOtpPrompt] = useState(false);
  const [timer, setTimer] = useState(60);
  const [isNewUser, setIsNewUser] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    let interval;
    if (timer > 0 && otpPrompt) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0 && otpPrompt) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer, otpPrompt]);

  const handleSubmit = (e) => {
    e?.preventDefault();
    dispatch(setIsLoading(true));

    const appVerifier = window.recaptchaVerifier;

    // console.log("phone: ", phoneNumber);

    signInWithPhoneNumber(auth, `+60${phoneNumber}`, appVerifier)
      // signInWithPhoneNumber(auth, `+600149942364`, appVerifier)
      // signInWithPhoneNumber(auth, `+60149942364`, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setOtpPrompt(true);
        dispatch(setIsLoading(false));
      })
      .catch((e) => {
        dispatch(setIsLoading(false));
        console.log(e, e.message);
        if (e.code === "auth/invalid-phone-number") {
          alert("Invalid phone number");
        } else {
          alert("Some error, refresh again and try");
        }
        window.location.reload(); // to get rid of recaptcha already rendered error
        const signInButton = document.getElementById("sign-in-button");
        if (signInButton && signInButton.firstChild) {
          signInButton.removeChild(signInButton.firstChild);
        }
        window.recaptchaVerifier = new RecaptchaVerifier(
          auth,
          "sign-in-button",
          {
            size: "invisible",
            callback: (response) => {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              // onSignInSubmit();
            },
          }
        );
      });
  };

  const handleOtpSubmit = (e) => {
    e.preventDefault();
    dispatch(setIsLoading(true));
    window.confirmationResult
      .confirm(otp)
      .then((result) => {
        const phoneNumber = result.user.phoneNumber;
        // console.log("user: ", phoneNumber, result.user.uid);

        const docRef = doc(db, "users", phoneNumber);
        getDoc(docRef).then((docSnap) => {
          if (docSnap.exists()) {
            const user = docSnap.data();

            // console.log("Login:", docSnap.data(), !user?.name && !user?.email);

            if (!user?.name && !user?.email) {
              // this is not working
              // console.log("if inside");
              dispatch(
                setUser({
                  id: result.user.uid,
                  phone: result.user.phoneNumber,
                  currentOrderId: user.currentOrderId,
                })
              );
              setIsNewUser(true);
              dispatch(setIsLoading(false));
            } else {
              // console.log("else inside");
              // name and email is there
              dispatch(
                setUser({
                  id: result.user.uid,
                  phone: result.user.phoneNumber,
                  currentOrderId: user.currentOrderId,
                  name: user?.name,
                  email: user?.email,
                })
              );
              dispatch(setIsLoading(false));
              // navigate(`/home?table-no=${tableNo}&type=${type}`);
            }

            // dispatch(setShowModal(false));
          } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document, Creating new one!");
            const userDetail = {
              id: result.user.uid,
              phone: result.user.phoneNumber,
              currentOrderId: "",
            };
            setDoc(doc(db, "users", result.user.phoneNumber), userDetail)
              .then(() => {
                // dispatch(setUser(userDet));
                // dispatch(setIsLoading(false));
                // navigate(`/home?table-no=${tableNo}&type=${type}`);

                dispatch(setUser(userDetail));
                setIsNewUser(true);
                dispatch(setIsLoading(false));
              })
              .catch((e) => console.log(e));
          }
        });
      })
      .catch((e) => {
        console.log("handleOtpSubmit: ", e);
        dispatch(setIsLoading(false));
      });
  };

  const updateUserDetails = (e) => {
    e.preventDefault();
    updateDoc(doc(db, "users", `+60${phoneNumber}`), {
      name,
      email,
    })
      .then(() => {
        console.log("Updated User details", userDet);
        dispatch(
          setUser({
            ...userDet,
            name,
            email,
          })
        );
        dispatch(setIsLoading(false));
        navigate(`/home?table-no=${tableNo}&type=${type}`);
      })
      .catch((e) => console.log(e));
  };

  const handleResendOTP = (e) => {
    e.preventDefault();
    // Reset timer to 60 seconds
    setTimer(60);
    // Your logic for resending OTP
    handleSubmit();
  };

  return (
    <div className={classes.bannerImg}>
      <div className={classes.innerSection}>
        <img
          className={classes.logo}
          src="/images/albarakahlogo.png"
          alt="no-img"
        />
        <div className={classes.shoplogodiv}>
          {" "}
          <img
            className={classes.shoplogo}
            src="/images/logo.png"
            alt="no-img"
          />
        </div>
        <h3 className={classes.loginheading}>Login</h3>

        <form className={`${classes.overallform} d-flex flex-wrap`}>
          <form style={{ marginTop: "20px" }}>
            <label className={classes.modalLabel} htmlFor="mobile-number">
              Enter Mobile Number
            </label>
            <input
              disabled={isNewUser || otpPrompt}
              className={classes.mobileInput}
              id="mobile-number"
              type="number"
              onChange={(e) => setPhoneNumber(e.target.value)}
              value={phoneNumber}
            />
          </form>
          <div style={{ width: "100%", marginBottom: "1.5rem" }}></div>

          {isNewUser ? (
            <>
              <form style={{ marginTop: "20px" }}>
                <label className={classes.modalLabel} htmlFor="name">
                  Enter Name
                </label>
                <input
                  className={classes.mobileInput}
                  id="name"
                  type="name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </form>
              <form style={{ marginTop: "20px" }}>
                <label className={classes.modalLabel} htmlFor="mail">
                  Enter Email
                </label>
                <input
                  className={classes.mobileInput}
                  id="mail"
                  type="mail"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </form>
              {/* <form style={{ marginTop: "20px" }}>
                <label className={classes.modalLabel} htmlFor="mobile-number">
                  Enter Mobile Number
                </label>
                <input
                  className={classes.mobileInput}
                  id="mobile-number"
                  type="number"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  value={phoneNumber}
                />
              </form> */}
              {/* <> */}
              <button
                onClick={updateUserDetails}
                style={{
                  width: "fit-content",
                  margin: "auto",
                  marginTop: "20px",
                }}
                className={classes.listBtn}
              >
                Submit
              </button>
              {/* <p className={classes.statusText}>
                  If you already a user?
                  <Link
                    className={classes.register}
                    to={`/?table-no=${tableNo}&type=${type}`}
                  >
                    Login
                  </Link>{" "}
                </p> */}
              {/* </> */}
            </>
          ) : otpPrompt && !isNewUser ? (
            <>
              <label className={classes.modalLabel} htmlFor="mobile-number">
                Enter OTP
              </label>
              <input
                className={classes.mobileInput}
                id="mobile-number"
                type="number"
                onChange={(e) => setOtp(e.target.value)}
                value={otp}
              />
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <p className={classes.didOtp}>
                  {timer > 0
                    ? `Resend OTP in ${timer} seconds`
                    : "Did you receive the OTP?"}
                </p>
                {timer === 0 && (
                  <button
                    className={classes.resendBtn}
                    onClick={handleResendOTP}
                  >
                    Resend OTP
                  </button>
                )}
              </Stack>

              <div style={{ width: "100%", marginBottom: "1.5rem" }}></div>
              <button
                onClick={handleOtpSubmit}
                style={{ width: "fit-content", margin: "auto" }}
                className={classes.listBtn}
              >
                Verify OTP
              </button>
            </>
          ) : (
            <>
              <button
                onClick={handleSubmit}
                style={{ width: "fit-content", margin: "auto" }}
                className={classes.listBtn}
              >
                Send OTP
              </button>
              <button
                onClick={() =>
                  navigate(`/home?table-no=${tableNo}&type=${type}`)
                }
                style={{
                  width: "fit-content",
                  margin: "auto",
                  marginTop: "5%",
                }}
                className={classes.listBtn}
              >
                View Menu
              </button>
              <p className={classes.statusText}>
                If you not login?
                <Link
                  className={classes.register}
                  to={`/register?table-no=${tableNo}&type=${type}`}
                >
                  Register
                </Link>{" "}
              </p>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default Login;
