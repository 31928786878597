import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "../services/firebase";
import {
  cookieOptions,
  getSingaporeDate,
  isLocalStorageSupports,
} from "../utils";
import Cookies from "js-cookie";

export const apiSlice = createApi({
  baseQuery: fakeBaseQuery(),
  endpoints: (build) => ({
    getSettings: build.query({
      queryFn: async () => {
        try {
          const collection = "appMeta";
          const docId = "settings";
          const docRef = doc(db, collection, docId);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            // console.log("Document data:", docSnap.data());
            return {
              data: docSnap.data(),
            };
          }
          // docSnap.data() will be undefined in this case
          return {
            // only returns data, error, meta
            error: {
              name: "invalid_id",
              message: `No such document - path: ${collection}/${docId}`,
              data: "",
            },
          };
        } catch (e) {
          return {
            error: {
              name: "getGettings",
              message: e?.message || "error",
              data: e,
            },
          };
        }
      },
    }),
    getItems: build.query({
      queryFn: async (category) => {
        try {
          const itemRef = collection(db, "items");
          // Create a query against the collection.
          const q = query(
            itemRef,
            where("isAvailable", "==", true),
            where("category.id", "==", category)
          );
          const querySnapshot = await getDocs(q);
          const arr = [];
          querySnapshot.forEach((doc) => {
            arr.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          // console.log("arr", arr);
          return { data: arr };
        } catch (e) {
          console.log(e);
          return {
            error: {
              name: "getItems",
              message: e?.message || "error",
              data: e,
            },
          };
        }
      },
    }),
    getItemById: build.query({
      queryFn: async (docId) => {
        try {
          const collection = "items";
          const docRef = doc(db, collection, docId);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            // console.log("Document data:", docSnap.data());
            return {
              data: docSnap.data(),
            };
          }
          // docSnap.data() will be undefined in this case
          return {
            // only returns data, error, meta
            error: {
              name: "invalid_id",
              message: `No such document - path: ${collection}/${docId}`,
              data: "",
            },
          };
        } catch (e) {
          return {
            error: {
              name: "getItemById",
              message: e?.message || "error",
              data: e,
            },
          };
        }
      },
    }),
    // getOrderById: build.query({
    //   queryFn: async (docId) => {
    //     try {
    //       const collection = "orders";
    //       const docRef = doc(db, collection, docId);
    //       const docSnap = await getDoc(docRef);

    //       if (docSnap.exists()) {
    //         // console.log("Document data:", docSnap.data());
    //         return {
    //           data: {
    //             id: docSnap.id,
    //             ...docSnap.data(),
    //           },
    //         };
    //       }
    //       // docSnap.data() will be undefined in this case
    //       return {
    //         // only returns data, error, meta
    //         error: {
    //           name: "invalid_id",
    //           message: `No such document - path: ${collection}/${docId}`,
    //           data: "",
    //         },
    //       };
    //     } catch (e) {
    //       return {
    //         error: {
    //           name: "getOrderById",
    //           message: e?.message || "error",
    //           data: e,
    //         },
    //       };
    //     }
    //   },
    // }),
    getOrderById: build.query({
      queryFn: async () => ({ data: null }),
      onCacheEntryAdded: async (
        args,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch }
      ) => {
        // _ as 1st param, it requried to get 2nd param, but not using, so kept as _
        let unsubscribe = () => {};
        // console.log("orders args: ", args);
        const docId = args.docId;
        // console.log("orders in slice", docId);
        try {
          if (docId) {
            const collection = "orders";

            await cacheDataLoaded;

            const currentOrdersRef = doc(db, collection, docId);

            unsubscribe = onSnapshot(currentOrdersRef, async (docSnap) => {
              if (docSnap.exists()) {
                const data = {
                  id: docSnap.id,
                  ...docSnap.data(),
                };
                console.log("Document data111:", data, data.status);
                if (data.status === "completed") {
                  alert("Thanks for choosing us, Please visit again!!!");

                  if (isLocalStorageSupports()) {
                    localStorage.removeItem("current-order-id");
                    localStorage.removeItem("cartItems");
                    localStorage.removeItem("table-detail");
                    localStorage.removeItem("order-detail");
                  } else {
                    Cookies.remove("current-order-id", cookieOptions);
                    Cookies.remove("cartItems", cookieOptions);
                    Cookies.remove("table-detail", cookieOptions);
                    Cookies.remove("order-detail", cookieOptions);
                  }

                  // store.clear();

                  window.location.href = "/home";
                } else {
                  localStorage.setItem("order-detail", JSON.stringify(data));
                  if (isLocalStorageSupports()) {
                    localStorage.setItem("order-detail", JSON.stringify(data));
                  } else {
                    Cookies.set(
                      "order-detail",
                      JSON.stringify(data),
                      cookieOptions
                    );
                  }
                  // store.set("order-detail", JSON.stringify(data));
                }
                updateCachedData((draft) => data);
                return {
                  data: data,
                };
              }
              // docSnap.data() will be undefined in this case
              return {
                // only returns data, error, meta
                error: {
                  name: "invalid_id",
                  message: `No such document - path: ${collection}/${docId}`,
                  data: "",
                },
              };
            });
          } // else if docId is null, then it is new order
        } catch (error) {
          console.log("error in tables subscription!", error);
          throw new Error("Something went wrong with tables.");
        }
        await cacheEntryRemoved;
        unsubscribe();
      },
    }),
  }),
});

export const {
  useGetSettingsQuery,
  useGetItemsQuery,
  useGetItemByIdQuery,
  useGetOrderByIdQuery,
} = apiSlice;
