import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import classes from "./OrderListCard.module.css";
import { Button, IconButton } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

const Img = styled("img")({
  margin: "auto",
});

export default function OrderListCard({
  item,
  handleClick,
  addNewItemToCart,
  removeItemFromCart,
  addItemQty,
  removeItemQty,
  cartItems,
}) {
  const [noOfItems, setNoOfItems] = useState(0);

  useEffect(() => {
    const currentItem = cartItems.find((cItem) => cItem.id === item.id);
    if (currentItem) {
      setNoOfItems(currentItem.quantity);
    } else {
      setNoOfItems(0);
    }
  }, [cartItems, item.id]);

  return (
    <Paper
      sx={{
        p: 1,
        margin: "auto",
        marginTop: "10px",
        maxWidth: 500,
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === "dark" ? "#1A2027" : "#fff",
      }}
    >
      <Grid container spacing={2} sx={{ height: "100%", width: "100%" }}>
        <Grid item>
          <ButtonBase onClick={() => handleClick(item)}>
            <Img
              alt="complex"
              //   sx={{ maxWidth: 50 }}
              className={classes.image}
              src={item.imgUrl}
            />
          </ButtonBase>
        </Grid>
        <Grid
          item
          xs
          container
          direction="column"
          spacing={2}
          onClick={() => handleClick(item)}
        >
          <Grid item xs>
            <Typography className={classes.title}>{item.name}</Typography>
          </Grid>
        </Grid>
        <Grid item className={classes.price_rm}>
          <Typography variant="subtitle1" component="div">
            <b className={classes.RM}>RM</b>{" "}
            <span className={classes.price}>{item.itemPrice}</span>
          </Typography>
          <Grid item>
            {noOfItems === 0 ? (
              <Button
                className={classes.add}
                variant="text"
                onClick={() => addNewItemToCart(item)}
              >
                add
              </Button>
            ) : (
              <div className={classes.orderListBtn}>
                <IconButton onClick={() => removeItemQty(item)}>
                  <RemoveIcon />
                </IconButton>
                {/* <Typography sx={{ cursor: "pointer" }} variant="body2"> */}
                <p className={classes.qty}>{noOfItems}</p>
                {/* </Typography> */}
                <IconButton onClick={() => addItemQty(item)}>
                  <AddIcon />
                </IconButton>
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
