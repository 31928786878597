import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from "../services/firebase";
import { setTableDetail, setTableError } from "../store/appSlice";
import {
  checkBrowser,
  cookieOptions,
  getSingaporeDate,
  isLocalStorageSupports,
} from "../utils";
import Cookies from "js-cookie";

const { apiSlice } = require("./apiSlice");

const tableSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getTableDetailsById: build.query({
      queryFn: async () => ({ data: null }),
      onCacheEntryAdded: async (
        args,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch }
      ) => {
        // _ as 1st param, it requried to get 2nd param, but not using, so kept as _
        let unsubscribe = () => {};
        // console.log("args: ", args);
        const user = args.user;
        const tableNo = args.tableNo;
        console.log("tableNo in slice", tableNo, user);

        try {
          // currentOrderId = null || "" || "orderId"
          // "" || "orderId" -> means getDoc is completed, so we are safe to add realtime listener
          // if (user.currentOrderId && user.id && user.phone) {
          if (user.id && user.phone) {
            // tableNo - available, scanned may be
            if (tableNo) {
              await cacheDataLoaded;

              const currentTableRef = doc(db, "tables", tableNo);

              unsubscribe = onSnapshot(currentTableRef, async (docSnap) => {
                // const currentDate = getSingaporeDate().getTime();
                const data = docSnap.data();

                if (data) {
                  if (data?.status === "free" && data?.isOccupied === false) {
                    // 1. new table request
                    // 2. when this person, order completes
                    // 3. what if the same user logged out? without login, he will not see the order
                    console.log("ud: ", user.phone, data.userScanned);
                    if (
                      data.currentOrderId &&
                      user.currentOrderId &&
                      data.currentOrderId === user.currentOrderId &&
                      user.phone === data.userScanned &&
                      data.isLock === false
                    ) {
                      // old user - order complete so remove the user currentOrderId
                      // we can remove it when admin completes the order
                      unsubscribe();
                      const userRef = doc(db, "users", user.phone);
                      await updateDoc(userRef, {
                        currentOrderId: "",
                      });
                      // to do, when this user logs-in again and when we found this order which is completed
                      // remove that order id from this user - can be done in checkout

                      window.location.href = "/";
                    } else if (
                      data.isLock === false &&
                      user.phone &&
                      data.userScanned &&
                      user.phone === data.userScanned
                    ) {
                      // to unlock the table
                      unsubscribe();
                      const tableRef = doc(db, "tables", tableNo);
                      await updateDoc(tableRef, {
                        userScanned: "",
                      });
                      window.location.href = "/";
                    } else {
                      console.log("elseeee");
                      // new user to the table, so send request
                      const tableRef = doc(db, "tables", tableNo);
                      await updateDoc(tableRef, {
                        status: "requested",
                        isOccupied: true,
                        isLock: true,
                        userScanned: user.phone, // introduce this field, so we can identify whether this is person is new or old
                      });
                    }
                  } else if (
                    data?.status === "rejected" &&
                    data?.isOccupied === true
                  ) {
                    // 1. remove the table and make the status free
                    // 2. path should not have table, so it will not request again

                    // dispatch(
                    //   setTableDetail({
                    //     data,
                    //     canPlaceOrder: true,
                    //   })
                    // );
                    unsubscribe();
                    const tableRef = doc(db, "tables", tableNo);
                    await updateDoc(tableRef, {
                      userScanned: "",
                      status: "free",
                      isOccupied: false,
                      isLock: false,
                    });

                    alert(
                      "Your request was rejected, Please contact respective person!!!"
                    );
                    window.location.href = "/";
                    // check this
                  } else if (
                    data?.status === "accepted" &&
                    data?.isOccupied === true
                  ) {
                    // console.log("table: ", data, user);
                    // 1. someother: just view the data and nothing else
                    // 2. same person: refreshes - it should show the previous orders, by checking the currendOrderId of that user with table currentOrderId
                    // 3. if admin, unlock the table, only this user should see the ordered items
                    if (data?.userScanned === user.phone) {
                      // same person
                      dispatch(
                        setTableDetail({
                          data,
                          canPlaceOrder: true,
                        })
                      );
                    } else {
                      dispatch(
                        setTableError({
                          isClose: true,
                          isTableError: true,
                          isTableMessage:
                            "This table is already booked by scanning. Please find another available table. If this is your table, only the person who scan the QR code can order. Others can only view the menu. If no one at your table can place an order, please ask for assistance from the staff.",
                          canPlaceOrder: false,
                        })
                      );
                    }
                  } else if (
                    data?.status === "requested" &&
                    data?.isOccupied === true
                  ) {
                    console.log("check", data, user);
                    // others - can't scan it
                    // same - wait for admin approval
                    if (data.userScanned !== user.phone) {
                      dispatch(
                        setTableError({
                          isClose: true,
                          isTableError: true,
                          isTableMessage:
                            "This table is already requested by someother person. Please find another available table. If this is your table, only the person who scans the QR code first can order. Others can only view the menu. If no one at your table can place an order, please ask for assistance from the staff.",
                          canPlaceOrder: false,
                        })
                      );
                    } else {
                      dispatch(
                        setTableDetail({
                          data,
                          canPlaceOrder: true,
                        })
                      );
                    }
                  }
                } else if (
                  data === undefined &&
                  (tableNo === "table-takeaway" || tableNo.includes("takeaway"))
                ) {
                  // console.log("check", tableNo, data);
                  dispatch(
                    setTableDetail({
                      data: {
                        name: "take-away",
                        status: "take-away",
                        tableNo: "take-away",
                        isOccupied: false, // always - as when others scanning, it has to scan
                      },
                      canPlaceOrder: true,
                    })
                  );
                }

                updateCachedData((draft) => data);
              });
            } else {
              // tableNo is null or undefined - so directly searching the url
              // public access
              dispatch(
                setTableError({
                  isClose: true,
                  canPlaceOrder: false,
                  isTableError: true,
                  isTableMessage:
                    "Please scan the table inside restuarant and don't use the link directly to place order, if QR code not working, please inform admin!!!",
                })
              );
            }
          } else if (!tableNo) {
            dispatch(
              setTableError({
                isClose: true,
                canPlaceOrder: false,
                isTableError: true,
                isTableMessage:
                  "Please scan the table inside restuarant and don't use the link directly to place order, if QR code not working, please inform admin!!!",
              })
            );
          }
        } catch (error) {
          console.log("error in tables subscription!", error);
          throw new Error("Something went wrong with tables.");
        }

        await cacheEntryRemoved;
        unsubscribe();
      },
    }),
  }),
});

export const { useGetTableDetailsByIdQuery } = tableSlice;
