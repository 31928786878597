import {
  Navigate,
  useLocation,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import Home from "./pages/Home";
import Order from "./pages/Order";
import MenuBook from "./pages/MenuBook";
import OrderDesc from "./pages/OrderDesc";
import Checkout from "./pages/Checkout";
import Login from "./pages/Login";
import { useGetTableDetailsByIdQuery } from "./api/tableSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RecaptchaVerifier, onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./services/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { selectTable, setIsLoading, setUser } from "./store/appSlice";
import Register from "./pages/Register";

export default function Router() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const tableNo = searchParams.get("table-no");
  const type = searchParams.get("type");
  // const status = searchParams.get("status");
  const { app } = useSelector(selectTable);

  window.recaptchaVerifier = new RecaptchaVerifier(auth, "sign-in-button", {
    size: "invisible",
    callback: (response) => {
      // reCAPTCHA solved, allow signInWithPhoneNumber.
      // console.log("response: ", response);
      // onSignInSubmit();
    },
  });

  useEffect(() => {
    // console.log(location.pathname);
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      dispatch(setIsLoading(true));
      // if no login, then user is null
      if (user) {
        const phoneNumber = user.phoneNumber;
        // console.log("user id: ", phoneNumber, user);

        // set
        if (phoneNumber) {
          const docRef = doc(db, "users", phoneNumber);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            // console.log("uid: ", user.uid, user.phone);
            // console.log("Document data:", docSnap.data());
            const userDet = docSnap.data();
            // this sets late
            dispatch(
              setUser({
                id: user.uid,
                phone: user.phoneNumber,
                currentOrderId: userDet.currentOrderId,
              })
            );
            dispatch(setIsLoading(false));

            if (userDet.name && userDet.email) {
              navigate(
                `${
                  location.pathname === "/" ? "/home" : location.pathname
                }?table-no=${tableNo}&type=${type}`
              );
            }
          } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
            dispatch(
              setUser({
                id: "",
                phone: "",
                currentOrderId: "",
              })
            );
            dispatch(setIsLoading(false));
          }
        } else {
          dispatch(setIsLoading(false));
        }
      } else {
        // User is signed out or there is no login
        // currentOrderId is null initially, and after this getDoc runs, it should be either "" or "orderId"
        dispatch(
          setUser({
            id: "",
            phone: "",
            currentOrderId: "",
          })
        );
        dispatch(setIsLoading(false));
      }
    });

    return unsubscribe;
  }, []);

  const { data, isError, isLoading, isFetching } = useGetTableDetailsByIdQuery({
    tableNo,
    user: app.user,
  });

  // const [authChecked, setAuthChecked] = useState(false);

  // const AuthenticatedRoute = ({ element, ...rest }) =>
  //   app.id && app.phone ? element : <Navigate to="/" />;

  const routes = useRoutes([
    {
      path: "/",
      element: <Login tableNo={tableNo} type={type} />,
    },
    {
      path: "/register",
      element: <Register tableNo={tableNo} type={type} />,
    },
    {
      path: "/home",
      element: <Home tableNo={tableNo} type={type} />,
    },
    {
      path: `/menu`,
      element: <MenuBook tableNo={tableNo} type={type} />,
    },
    {
      path: "/items",
      element: <Order tableNo={tableNo} type={type} />,
    },
    {
      path: "/item/:id/item-desc",
      element: <OrderDesc tableNo={tableNo} type={type} />,
    },
    {
      path: "/checkout",
      element: <Checkout tableNo={tableNo} type={type} />,
    },
  ]);

  return routes;
}
