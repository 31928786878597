import { Box, Stack, Typography } from "@mui/material";
import classes from "./ItemsDesc.module.css";
import React from "react";

const ItemsDesc = ({ item }) => {
  // console.log("itemDesc: ", item);
  return (
    <div className={classes.descContainer}>
      {/* image should be not in css */}
      <Box
        className={classes.itemoverall}
        style={{
          backgroundImage: `url(${item.imgUrl})`,
        }}
      ></Box>
      <div className={classes.descContent}>
        <Stack
          className={classes.body}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
          <Typography className={classes.itemtitle}>{item?.name}</Typography>
          <Typography>
            <b className={classes.RM}>RM</b>{" "}
            <span className={classes.price}>{item?.itemPrice}</span>
          </Typography>
        </Stack>
        <div className={classes.categorytype}>
          <div>
            <Typography className={classes.foodtitle}>Category:</Typography>
            <Typography className={classes.non}>
              <b className={classes.foodtype2}>{item?.category.name}</b>
            </Typography>
          </div>
          <div>
            <Typography className={classes.foodtitle}>Sub-Category:</Typography>
            <Typography className={classes.non}>
              <b className={classes.foodtype2}>{item?.subCategory.name}</b>
            </Typography>
          </div>
        </div>
        <Stack className={classes.foodtype}>
          <Typography className={classes.foodtitle}>Food Type:</Typography>
          <Typography className={classes.non}>
            {/* non-veg image */}
            <img
              src={`/images/${item?.foodType === "Veg" ? "veg" : "nonveg"}.png`}
              alt={item?.foodType}
              className={classes.nonveg}
            />
            <b className={classes.foodtype2}>{item?.foodType}</b>
          </Typography>
        </Stack>
        <Stack className={classes.desc}>
          <Typography component="h6" className={classes.desctitle}>
            Description
          </Typography>
          <Typography className={classes.description}>
            {item?.desc ||
              "Tasty item in the flavour of indian spicies Tasty item in the flavour of indian spicies Tasty item in the flavour of indian spicies Tasty item in the flavour of indian spicies"}
          </Typography>
        </Stack>
      </div>
    </div>
  );
};

export default React.memo(ItemsDesc);
